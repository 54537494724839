// @flow
import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import FlexCard from "./flex-card";
import {Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import RouteService from "../../services/route-service";
import {RequestRegistrationController$AnimalSimilarityJson} from "../../api/generated/registry-rest";
import {AnimalService} from "../../services/animal-service";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {PanonIdentifier} from "../../api/generated/herd-animal";
import {useNav} from "../Common/hooks/use-nav";

interface ReviewSimilaritiesCardProps {
    similarAnimals: RequestRegistrationController$AnimalSimilarityJson[],
    reviewedAnimal: JsonAnimal,
    reviewId: string
}

const ReviewSimilaritiesCard: FunctionComponent<ReviewSimilaritiesCardProps> = ({reviewId, reviewedAnimal, similarAnimals}) => {
    const nav = useNav();

    const [similarAnimalsDetails, setSimilarAnimalsDetails] = useState<JsonAnimal[]>([]);

    const handleSimilarityCardClick = (reviewId: string, similarAnimal: JsonAnimal) => {
        nav.push(
            RouteService.REGISTRY_ANIMAL_REVIEW_COMPARE_SIMILARITIES,
            {
                reviewedAnimalPanonId: reviewedAnimal.panonIdentifier.id,
                similarAnimalPanonId: similarAnimal.panonIdentifier.id
            },
            {
                reviewedAnimal,
                similarAnimal
            });
    }

    const findMatchingSaByPanonId = (panonId: PanonIdentifier): RequestRegistrationController$AnimalSimilarityJson | undefined => similarAnimals.find(simAnimal => simAnimal.panonIdentifier === panonId.id);


    const initialize = async (similarAnimals: RequestRegistrationController$AnimalSimilarityJson[]) => {
        const similarAnimalPromises: Promise<JsonAnimal>[] = similarAnimals.map(sa => AnimalService.loadJsonAnimalByPanonId(sa.panonIdentifier));
        setSimilarAnimalsDetails(await Promise.all(similarAnimalPromises));
    }

    useEffect(() => {
        initialize(similarAnimals);
    }, [similarAnimals])

    const renderOverlappingPropertiesIndicator = (animal: JsonAnimal) => {
        const matching: RequestRegistrationController$AnimalSimilarityJson | undefined = findMatchingSaByPanonId(animal.panonIdentifier);
        if (matching && matching.score > 4) {
            return (<ErrorIcon alignmentBaseline="baseline" fontSize="large" color="warning"/>)
        } else if (matching) {
            return (
                <Typography variant="subtitle2" color="text.primary" sx={{display: 'inline'}}
                            component="span">
                    {matching.score}
                </Typography>
            )
        }
        return null;

    }

    //TODO avatarUrl is needed in JsonAnimal

    return (
        <FlexCard title="Ähnlich zu">
            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                {similarAnimalsDetails.map(sa =>
                    <Fragment>
                        <ListItem alignItems="flex-start" button={true} onClick={() => handleSimilarityCardClick(reviewId, sa)}>
                            <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src="/frosty.jpg"/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={sa.herdCode + " " + sa.name + " - " + sa.dateOfBirth.toLocaleString()}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{display: 'inline'}}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            Ähnlichkeiten:
                                        </Typography>
                                        {renderOverlappingPropertiesIndicator(sa)}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li"/>
                    </Fragment>
                )}
            </List>
        </FlexCard>
    );
};

export default ReviewSimilaritiesCard
