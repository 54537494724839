import React, {FC, useState} from 'react';
import Widget from "../Common/widget";
import {RouteComponentProps, withRouter} from "react-router-dom";
import TabularTextView from './Common/tabular-text-view';
import AnimalBaseDataForm from "../AnimalCreate/animal-base-data-form";
import {AnimalService} from "../../services/animal-service";
import {useGlobalSnackbarStore} from "../../stores/global-snackbar-store";
import {AnimalBaseData} from "../../api/dtos/animal-base-data";
import {useTranslation} from 'react-i18next';


interface BaseDataProps extends RouteComponentProps {
    initialBaseData: AnimalBaseData
    editable?: boolean
    deactivatable?: boolean,
    onSubmit?: (animalBaseData: AnimalBaseData) => void
}

const transformAnimalBaseData = (t: (id: string) => string, animal: AnimalBaseData): Map<string, any> => {
    const m: Map<string, any> = new Map<string, any>();
    m.set("Herdencode", animal.herdCode);
    m.set("Name", animal.name);
    m.set("Geschlecht", t('sex.' + animal.sex));
    m.set("Farbe", t('color.' + animal.fiberColor));
    m.set("Geburtstag", animal.dateOfBirth?.toLocaleString() ?? "n.a.");
    m.set("Rasse", t('breed.' + animal.breed));
    return m;
};

const BaseData: FC<BaseDataProps> = ({initialBaseData, editable = true, deactivatable = false, onSubmit}) => {
    const {name, herdCode} = initialBaseData;

    const {success} = useGlobalSnackbarStore((state) => state);

    const [animalBaseData, setAnimalBaseData] = useState(initialBaseData)
    const [editOpen, setEditOpen] = useState(false)
    const {t} = useTranslation();

    const handleEditFormSubmit = async (update: AnimalBaseData) => {
            if(onSubmit) onSubmit(update);
            setAnimalBaseData(update)
            setEditOpen(false)
    }

    const handleAnimalDeactivate = async () => {
        await AnimalService.deactivateAnimal(animalBaseData.panonIdentifier);
        success("Tier wurde deaktiviert!");
    }

    const buildCardMenuEntries = () => {
        const entries = [];
        if(editable) { entries.push({actionName: "Bearbeiten", actionFunction: () => setEditOpen(true)})}
        if(deactivatable) {entries.push({actionName: "Deaktivieren", actionFunction: handleAnimalDeactivate})}
        return entries;
    }

    return (
        <Widget displayName={`Stammdaten von ${herdCode} ${name}`} editable={editable || deactivatable} cardMenuEntries={buildCardMenuEntries()}>
            {editOpen ? <AnimalBaseDataForm onSubmitClicked={handleEditFormSubmit}
                                            onCancelClicked={() => setEditOpen(false)}
                                            initialData={animalBaseData}
                                            allowIdUpdate={false}/> :
                <TabularTextView displayPairs={transformAnimalBaseData(t, animalBaseData)}/>
            }
        </Widget>
    );
};

export default withRouter(BaseData);
