import React, {Fragment, FunctionComponent, ReactElement, useState} from "react";
import AnimalSearchResult from "../../api/dtos/animal-search-result";
import FlexStack from "./flex-stack";
import {SearchField} from "./index";
import {Stack, SxProps, Theme} from "@mui/material";
import AnimalSearchResultCard from "./animal-search-result-card";
import {SearchService} from "../../services/search-service";

const MIN_SEARCH_TERM_LENGTH = 3;



interface AnimalSearchProps {
    onBeforeShowSuggestions?: (searchResults: AnimalSearchResult[]) => AnimalSearchResult[]
    handleResultClick: (clickedResult: AnimalSearchResult) => void
    renderOnEmptyResult?: ReactElement;
    sx?: SxProps<Theme>
    label?: string
    searchRegistrations?: boolean
    searchHerdAndPedigree?: boolean
}

type OptionalReactElement = React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;

function SearchResultsRenderer(props: { animalSearchResults: AnimalSearchResult[], currentSearchTerm: string, renderOnEmptyResult: OptionalReactElement, onResultClick: (a: AnimalSearchResult) => void }) {
    if (props.currentSearchTerm !== null && props.currentSearchTerm.length < MIN_SEARCH_TERM_LENGTH) {
        return null;
    } else if (props.animalSearchResults.length === 0) {
        return props.renderOnEmptyResult || null;
    } else {
        return <Fragment>{props.animalSearchResults.map(a => <AnimalSearchResultCard key={a.panonId.id} animalSearchResult={a} handleClick={() => props.onResultClick(a)}
                                                                                     clickable={true}/>)}</Fragment>
    }
}

const AnimalSearch: FunctionComponent<AnimalSearchProps> = ({
                                                                onBeforeShowSuggestions,
                                                                handleResultClick,
                                                                renderOnEmptyResult,
                                                                sx,
                                                                label,
                                                                searchRegistrations = true,
                                                                searchHerdAndPedigree = true
                                                            }) => {

    const [animal, setAnimal] = useState<AnimalSearchResult | null>(null);
    const [foundAnimals, setFoundAnimals] = useState<AnimalSearchResult[]>([]);
    const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('');

    const performSearch = async (searchTerm: string): Promise<AnimalSearchResult[]> => {
        let foundAnimals: AnimalSearchResult[] = [];
        if (searchTerm && searchTerm.length >= MIN_SEARCH_TERM_LENGTH) {
            const searchRegistrationsPromise: Promise<AnimalSearchResult[]> | null = searchRegistrations ? SearchService.searchRegisteredAnimals(searchTerm) : null;
            const searchHerdAndPedigreePromise: Promise<AnimalSearchResult[]> | null = searchHerdAndPedigree ? SearchService.searchHerdAndPedigreeAnimals(searchTerm) : null;
            const registered = searchRegistrationsPromise !== null ? await searchRegistrationsPromise : [];
            let herdAndPedigree = searchHerdAndPedigreePromise !== null ? await searchHerdAndPedigreePromise : [];
            const combinedResults = combineResults(registered, herdAndPedigree);
            foundAnimals = onBeforeShowSuggestions ? onBeforeShowSuggestions(combinedResults) : combinedResults;
        }
        setFoundAnimals(foundAnimals);
        setCurrentSearchTerm(searchTerm);
        return [];
    }

    /**
     * Combine Results for registered animals with herd and pedigree animals. If a search result for a registered animal exists, the pedigree and herd result is dropped for that
     * panon id.
     */
    const combineResults = (registrations: AnimalSearchResult[], herdAndPedigree: AnimalSearchResult[]): AnimalSearchResult[] => {
        const registrationPanonIds = registrations.map(sr => sr.panonId.id);
        const filteredHerdAndPedigree = herdAndPedigree.filter(r => !registrationPanonIds.includes(r.panonId.id));
        return registrations.concat(filteredHerdAndPedigree);

    }

    return (
        <FlexStack sx={{...sx}}>
            <SearchField<AnimalSearchResult> label={label ? label : "Tier suchen"} showSearchIcon={true} findSuggestions={performSearch} renderOption={a => a.herdCode + a.name}
                                             optionUniqueKey={a => a.panonId.id} value={animal} onValueChange={setAnimal}/>
            <Stack spacing={1}>
                <SearchResultsRenderer animalSearchResults={foundAnimals} currentSearchTerm={currentSearchTerm} renderOnEmptyResult={renderOnEmptyResult}
                                       onResultClick={handleResultClick}/>
            </Stack>
        </FlexStack>
    );
};

export default AnimalSearch