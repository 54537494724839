import {DateTime} from "luxon";

export const getPregnancyDurationDays = (pregnancy: {actualEndDate: DateTime, startDate: DateTime}) => {
    const endDate = pregnancy.actualEndDate ?? DateTime.now();
    return Math.round(endDate.diff(pregnancy.startDate).as("days"));
};

export enum PregnancyState {
    MATED,
    UNSUCCESSFUL,
    PREGNANT,
    COMPLETED,
}

export const getPregnancyState = ({matingDate, startDate, actualEndDate}: {
    matingDate: DateTime | undefined | null,
    startDate: DateTime | undefined | null,
    actualEndDate: DateTime | undefined | null
}): PregnancyState | null => {
    switch (true) {
        case Boolean(matingDate && !startDate && !actualEndDate): return PregnancyState.MATED;
        case Boolean(matingDate && !startDate && actualEndDate): return PregnancyState.UNSUCCESSFUL;
        case Boolean(startDate && !actualEndDate): return PregnancyState.PREGNANT;
        case Boolean(actualEndDate): return PregnancyState.COMPLETED;
        default: return null;
    }
};