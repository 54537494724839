// @flow
import {IconButton, List, ListItem, ListItemText, Typography} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {useQuery} from 'react-query';
import {BreederService} from "../services/breeder-service";
import {AddLink} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import RouteService from "../services/route-service";
import FlexStack from '../components/Common/flex-stack';


const AdminAccountsLink: FunctionComponent = () => {

    const unlinkedBreeders = useQuery('unlinked-breeders',() => BreederService.listUnlinked());
    const history = useHistory();
    const handleAddLinkClick = (breederName: string) => {
       history.push(RouteService.expand(RouteService.ADMIN_LINK_ACCOUNT_WITH_BREEDER_EDITOR, {breederName}));
    }

    return (
        <FlexStack>
            <Typography variant="h4">Unverlinkte Züchter</Typography>
            <List>
                {unlinkedBreeders.data && unlinkedBreeders.data.map((breeder:string) => (
                    <ListItem key={breeder}
                              secondaryAction={
                                  <IconButton edge="end" aria-label="link" onClick={() => handleAddLinkClick(breeder)}>
                                      <AddLink />
                                  </IconButton>
                              }
                    >
                        <ListItemText primary={breeder}/>
                    </ListItem>
                ))}
            </List>
        </FlexStack>
    )
        ;
};

export default AdminAccountsLink