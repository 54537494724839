// @flow
import React, {FunctionComponent} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {FarmData} from "../../api/dtos/breeder-update";
import {Box, Button, IconButton, Stack, TextField, Typography} from "@mui/material";
import FlexStack from "../Common/flex-stack";
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SaveIcon from "@mui/icons-material/Save";

interface BreederDetailsFormProps {
    onSubmit: (data: FarmData) => void;
    initialData: FarmData;
}


const requiredFieldConfig = {required: {value: true, message: "Pflichtfeld"}} //TODO get i18n to work

const BreederDetailsForm: FunctionComponent<BreederDetailsFormProps> = ({onSubmit, initialData}) => {
    const {
        formState: {errors},
        register,
        handleSubmit,
        control
    } = useForm<FarmData>({
        defaultValues: initialData
    })
    const {
        fields,
        append,
        remove
    } = useFieldArray({control, name: "owners"})



    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
                <FlexStack>
                    <Typography variant="h4">Farm Daten</Typography>
                    <TextField label="Farm Name" required
                               {...register("farmName", requiredFieldConfig)} error={!!errors.farmName}
                               helperText={errors.farmName?.message ?? ''}/>
                    <TextField label="Herdkürzel" required
                               {...register("herdCode", requiredFieldConfig)} error={!!errors.herdCode}
                               helperText={errors.herdCode?.message ?? ''}/>
                    <TextField label="Email" required
                               {...register("emailAddress", requiredFieldConfig)} error={!!errors.emailAddress}
                               helperText={errors.emailAddress?.message ?? ''}/>
                    <TextField label="Webseite" {...register("website")}/>
                    <TextField label="Telefonnummer" {...register("phone")}/>
                    <Typography style={{marginTop: "50px"}} variant="h5">Adresse</Typography>
                    <TextField label="Land" required
                               {...register("address.country", requiredFieldConfig)} error={!!errors.address?.country}
                               helperText={errors.address?.state?.message ?? ''}/>
                    <TextField label="Bundesland" required
                               {...register("address.state", requiredFieldConfig)} error={!!errors.address?.state}
                               helperText={errors.address?.state?.message ?? ''}/>
                    <TextField label="Stadt" required
                               {...register("address.city", requiredFieldConfig)} error={!!errors.address?.city}
                               helperText={errors.address?.city?.message ?? ''}/>
                    <TextField label="Postleitzahl" required
                               {...register("address.zipCode", requiredFieldConfig)} error={!!errors.address?.zipCode}
                               helperText={errors.address?.zipCode?.message ?? ''}/>
                    <TextField label="Straße und Hausnummer" required
                               {...register("address.street", requiredFieldConfig)} error={!!errors.address?.street}
                               helperText={errors.address?.street?.message ?? ''}/>

                    <Stack direction="row" style={{marginTop: "50px"}}>
                        <Typography variant="h4">Besitzer</Typography>
                        <IconButton color="primary" aria-label="add owner"
                                    onClick={() => append({name: ""})}>
                            <PersonAddIcon/>
                        </IconButton>
                    </Stack>
                    {fields.map((field, index) => (
                        <Stack style={{marginTop: "50px"}} key={field.id}>
                            <Stack direction="row">
                                <Typography variant="h5">Besitzer {index + 1}</Typography>
                                <IconButton color="primary" size="small" aria-label="add owner"
                                            onClick={() => remove(index)}>
                                    <PersonRemoveIcon/>
                                </IconButton>
                            </Stack>
                            <TextField label="Vorname, Nachname" required
                                       {...register(`owners.${index}.name`, requiredFieldConfig)}
                                       error={!!errors.owners?.[index]?.name}
                                       helperText={errors.owners?.[index]?.name?.message ?? ''}/>
                        </Stack>
                    ))}

                    <Button variant="contained"
                            startIcon={<SaveIcon/>}
                            type="submit"
                    >
                        Speichern
                    </Button>
                </FlexStack>
            </form>
        </Box>
    );
};


export default BreederDetailsForm