import {DateTime} from 'luxon';
// @ts-ignore
const sortByDateASC = <T, K extends keyof any & { [K in keyof T]: T[K] extends DateTime ? K : never }[keyof T]>(field: K) => (a: T, b: T) => a[field].toMillis() - b[field].toMillis();

// @ts-ignore
const sortByDateDESC = <T, K extends keyof any & { [K in keyof T]: T[K] extends DateTime ? K : never }[keyof T]>(field: K) => (a: T, b: T) => b[field].toMillis() - a[field].toMillis()

const PANON_MIN_UTC_DATE_TIME = DateTime.utc(1970, 1, 1, 12, 0, 0, 0);
const PANON_MAX_UTC_DATE_TIME = DateTime.utc(2200, 1, 1, 12, 0, 0, 0);

const DateTimeService = {
    sortByDateASC,
    sortByDateDESC,
    PANON_MAX_UTC_DATE_TIME,
    PANON_MIN_UTC_DATE_TIME
}

export default DateTimeService;