import {DateTime} from "luxon";

export default interface ReviewSimilaritiesComparisonDto {
    comparisonPairs: ComparisonPair[],
    avatarUrlReviewAnimal: string,
    avatarUrlSimilarAnimal: string
}

export class ComparisonPair {
    private _name: string;
    private _firstValue: string | number | DateTime;
    private _secondValue: string | number | DateTime;

    constructor(name: string, firstValue: any, secondValue: any) {
        this._name = name;
        this._firstValue = firstValue;
        this._secondValue = secondValue;
    }

    public isEqual(): boolean {
        if (DateTime.isDateTime(this.firstValue)) {
            const dt1 = this.firstValue as DateTime;
            const dt2 = this.secondValue as DateTime;
            return dt1.toMillis() === dt2.toMillis();
        }
        return this.firstValue === this.secondValue;
    }

    get name(): string {
        return this._name;
    }

    get firstValue(): any {
        return this._firstValue;
    }

    get secondValue(): any {
        return this._secondValue;
    }
}