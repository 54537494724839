import {useQuery} from "react-query";
import {AnimalService} from "../../../services/animal-service";

export const useAnimal = (panonId: string | undefined, suspense: boolean = false) => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['animal', panonId],
        queryFn: () => AnimalService.loadJsonAnimalByPanonId(panonId!),
        enabled: !!panonId,
        suspense,
    });

    return {
        animal: data,
        isLoading,
        isError,
    };
};