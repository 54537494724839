import {Chip} from "@mui/material";
import React from "react";
import AuthService from "../services/auth-service";
import {PANON_ADMIN, REGISTRY_WARDEN} from "./Permissions/Roles";

export const RolesHint = () => {
    if (AuthService.hasRole([PANON_ADMIN])) {
        return <Chip label="Admin" color="error"/>
    }
    if (AuthService.hasRole([REGISTRY_WARDEN])) {
        return <Chip label="Zuchtwart" color="warning"/>
    }
    return <></>;
};