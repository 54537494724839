import {TreatmentListJson} from "../../../api/generated/medical-rest";

export type FormattingFunction = (treatment: TreatmentListJson) => string;
export interface TypeFormatters {
    [type:string]: FormattingFunction
}

export interface TreatmentFormatterConfiguration {
    defaultFormatting?: FormattingFunction
    typeFormatting?: TypeFormatters
}

class TreatmentFormatter {
    private readonly config: TreatmentFormatterConfiguration;

    constructor(config: TreatmentFormatterConfiguration) {
        this.config = config;
    }

    format(treatment: TreatmentListJson): string {
        let formattingFkt = this.config.typeFormatting ? this.config.typeFormatting[treatment.type] : undefined;
        if (!formattingFkt) {
            if (this.config.defaultFormatting) {
                formattingFkt = this.config.defaultFormatting;
            }
        }
        return formattingFkt ? formattingFkt(treatment) : treatment.type;
    }

}

export default TreatmentFormatter