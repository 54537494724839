import {AlpacaFiberColor, AlpacaFiberColorValues, Sex, SexValues} from "../api/generated/herd-animal";

const translateSex = (sex: Sex) => {
    switch(sex) {
        case SexValues.MALE: return "Männlich"
        case SexValues.FEMALE: return "Weiblich"
        case SexValues.UNKNOWN: return "Unbekannt"
    }
}

const translateFiberColor = (fiberColor: AlpacaFiberColor) => {
    switch(fiberColor){
        case AlpacaFiberColorValues.WHITE: return "White"
        case AlpacaFiberColorValues.BEIGE: return "Beige"
        case AlpacaFiberColorValues.LIGHT_FAWN: return "Light Fawn"
        case AlpacaFiberColorValues.MEDIUM_FAWN: return "Medium Fawn"
        case AlpacaFiberColorValues.DARK_FAWN: return "Dark Fawn"
        case AlpacaFiberColorValues.LIGHT_BROWN: return "Light Brown"
        case AlpacaFiberColorValues.MEDIUM_BROWN: return "Medium Brown"
        case AlpacaFiberColorValues.DARK_BROWN: return "Dark Brown"
        case AlpacaFiberColorValues.BAY_BLACK: return "Bay Black"
        case AlpacaFiberColorValues.TRUE_BLACK: return "True Black"
        case AlpacaFiberColorValues.LIGHT_SILVER_GREY: return "Light Silvergrey"
        case AlpacaFiberColorValues.MEDIUM_SILVER_GREY: return "Medium Silvergrey"
        case AlpacaFiberColorValues.DARK_SILVER_GREY: return "Dark Silvergrey"
        case AlpacaFiberColorValues.LIGHT_ROSE_GREY: return "Light Rosegrey"
        case AlpacaFiberColorValues.MEDIUM_ROSE_GREY: return "Medium Rosegrey"
        case AlpacaFiberColorValues.DARK_ROSE_GREY: return "Dark Rosegrey"
        case AlpacaFiberColorValues.MULTICOLOR: return "Multicolor"
        case AlpacaFiberColorValues.UNKNOWN:
        default: return "Unbekannt"
    }
}

const translateBreed = (breed:string) => {
    switch(breed) {
        case "10": return "Huacaya"
        case "20": return "Suri"
        default: return "N.A."
    }
}

const I18NService
    = {
    translateSex,
    translateFiberColor,
    translateBreed
};

export default I18NService;