import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemLink from "./list-item-link";
import RenderOnAuthenticated from "./Permissions/render-on-authenticated";
import * as PropTypes from "prop-types";
import React, {FunctionComponent, Suspense} from "react";
import {
    Box,
    CircularProgress,
    Collapse,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SxProps,
    Toolbar
} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RouteService from "../services/route-service";
import RenderOnRole from "./Permissions/render-on-role";
import {RenderOnFeatureFlag} from "./Common/render-on-feature-flag";
import {AnimalRecordStatusValues} from "../api/generated/herd-animal";
import {PANON_ADMIN, REGISTRY_WARDEN} from "./Permissions/Roles";
import {HerdQuickList} from "./Navigation/herd-quick-list";
import {AdminPanelSettings, Assignment, Home, People, Pets} from "@mui/icons-material";
import HelpIcon from '@mui/icons-material/Help';

interface MenuProperties {
    onClick?: () => void
    sx: SxProps
}

const LoadingFallback = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress/>
    </Box>
);

const NavigationMenu: FunctionComponent<MenuProperties> = ({onClick = (() => undefined), sx}) => {

    const [openAdminArea, setOpenAdminArea] = React.useState(true);

    const handleAdminAreaClick = () => setOpenAdminArea(!openAdminArea);

    return <Box sx={sx}>
        <Toolbar/>
        <Divider/>
        <List component="nav" sx={{'& .MuiListItemIcon-root': {minWidth:'32px'}}}>
            <ListItemLink onClickHandler={onClick}
                          to={RouteService.HOME}
                          icon={<Home/>}
                          primary="Dashboard"/>
            <RenderOnAuthenticated>
                <ListItemLink onClickHandler={onClick}
                              to={RouteService.expand(RouteService.HERD_GROUP, {groupId: "all", recordStatus: AnimalRecordStatusValues.HERD})}
                              icon={<Pets/>}
                              primary="Meine Herde"/>
                <List disablePadding sx={{ pl: 5 }}>
                    <ListItemLink
                        onClickHandler={onClick}
                        to={RouteService.HERD_PREGNANCIES_LIST}
                        primary="Trächtigkeiten"/>
                    <ListItemLink
                        onClickHandler={onClick}
                        to={RouteService.expand(RouteService.HERD_GROUP, {groupId: "all", recordStatus: AnimalRecordStatusValues.INACTIVE})}
                        primary="Inaktive Tiere"/>
                </List>

                <ListItemLink onClickHandler={onClick}
                              to={RouteService.REGISTRY_ANIMAL_SEARCH}
                              icon={<Assignment/>}
                              primary="Register"/>
                <List disablePadding sx={{ pl: 5 }}>
                    <ListItemLink onClickHandler={onClick} to={RouteService.REGISTRY_MY_REGISTRATIONS_LIST} primary="Meine Anmeldungen"/>
                    <RenderOnRole roles={[REGISTRY_WARDEN, PANON_ADMIN]}>
                        <ListItemLink onClickHandler={onClick} to={RouteService.REGISTRY_SEARCH} primary="Registeradministration"/>
                        <ListItemLink onClickHandler={onClick} to={RouteService.REGISTRY_ANIMAL_REVIEW_LIST} primary="Registeranmeldungen"/>
                    </RenderOnRole>
                </List>

                <ListItemLink onClickHandler={onClick}
                              to={RouteService.BREEDERS_LIST}
                              icon={<People/>}
                              primary="Züchter"/>

                <RenderOnFeatureFlag  featureFlag={"ADMIN_AREA"}>
                    <RenderOnRole roles={[PANON_ADMIN]}>
                        <ListItemButton onClick={handleAdminAreaClick}>
                            <ListItemIcon><AdminPanelSettings/></ListItemIcon>
                            <ListItemText primary="Admin Area" />
                            {openAdminArea ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openAdminArea} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ pl: 5 }}>
                                <ListItemLink onClickHandler={onClick} to={RouteService.ADMIN_LINK_ACCOUNT_WITH_BREEDER} primary="Accounts Verlinken"/>
                            </List>
                        </Collapse>
                    </RenderOnRole>
                </RenderOnFeatureFlag>
                <ListItemLink onClickHandler={onClick} primary="Hilfe" to={RouteService.HELP} icon={<HelpIcon/>} />
            </RenderOnAuthenticated>
        </List>
        <RenderOnAuthenticated>
            <Divider />
            <Suspense fallback={<LoadingFallback />}>
                <HerdQuickList/>
            </Suspense>
        </RenderOnAuthenticated>
    </Box>;
}

NavigationMenu.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default NavigationMenu;
