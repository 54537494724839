// @flow
import React, {FunctionComponent} from 'react';
import {Alert} from '@mui/material';
import {Widget} from "../../Common";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {useHistory} from "react-router-dom";
import Pedigree from "../../Pedigree/pedigree";
import RouteService from "../../../services/route-service";
import EditIcon from "@mui/icons-material/Edit";

interface PedigreeWidgetProps {
    animal: JsonAnimal
    editable?: boolean
    isRegistered: boolean
    isRegistryWarden?: boolean
    expandedRedirectUrl?: string
};


const PedigreeWidget: FunctionComponent<PedigreeWidgetProps> = ({animal, editable = false, isRegistered, isRegistryWarden = false, expandedRedirectUrl}) => {
    const history = useHistory();

    const editAncestryAction = () => history.push({
            pathname: RouteService.expand(RouteService.ANIMAL_CHANGE_ANCESTRY, {panonId: animal.panonIdentifier.id})
        },
        {expandedRedirectUrl});
    return (
        <Widget displayName="Stammbaum"
                editable={!isRegistered || isRegistryWarden}
                cardMenuEntries={[{
                    actionName: "Stammbaum bearbeiten",
                    actionFunction: editAncestryAction
                }]}
                quickActions={[{
                    icon: <EditIcon />,
                    action: editAncestryAction,
                }]}>
            {isRegistered ? (<Alert sx={{marginBottom: 1}} severity="info">Dieser Stammbaum wird durch das Register verwaltet</Alert>) : null}
            <Pedigree animal={animal}/>
        </Widget>
    );
};

export default PedigreeWidget
