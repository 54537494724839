import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {useAnimal} from "../../Common/hooks/use-animal";
import {Document, Link, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import React, {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {ArrowBack, Downloading, FileDownload} from "@mui/icons-material";
import {useQuery} from "react-query";
import {PedigreeService} from "../../../services/pedigree-service";
import {PedigreeDto} from "../../../api/dtos/pedigree-dto";
import {Button, Stack} from "@mui/material";
import RouteService from "../../../services/route-service";

const styles = StyleSheet.create({
    page: {
        padding: '2cm',
        fontSize: 14,
        fontFamily: 'Times-Roman',
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '6mm',
        marginBottom: '4mm',
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '6mm',
        marginBottom: '4mm',
    },
    footer: {
        fontSize: 12,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '0 2cm 1cm',
    },
});

const PdfTitle = ({children}: PropsWithChildren<{}>) => (
    <Text style={styles.title}>{children}</Text>
);

const PdfHeading = ({children}: PropsWithChildren<{}>) => (
    <Text style={styles.heading}>{children}</Text>
);

const Definition = ({title, children}: PropsWithChildren<{ title: string }>) => (
    <View style={{flexDirection: 'row', gap: 4}}>
        <Text style={{minWidth: '30vw'}}>{title}</Text>
        <Text style={{minWidth: '70vw'}}>{children}</Text>
    </View>
)

type AnimalDocumentProps = {
    animal: JsonAnimal,
    pedigree: PedigreeDto | undefined,
};

const AnimalDocument = ({animal, pedigree}: AnimalDocumentProps) => {
    const {t} = useTranslation();

    const dam = pedigree?.root.dam;
    const sire = pedigree?.root.sire;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <PdfTitle>{animal.herdCode} {animal.name}</PdfTitle>

                <PdfHeading>Stammdaten</PdfHeading>

                <View>
                    <Definition title="Herdencode:">{animal.herdCode}</Definition>
                    <Definition title="Name:">{animal.name}</Definition>
                    <Definition title="Geschlecht:">{t('sex.' + animal.sex)}</Definition>
                    <Definition title="Farbe:">{t('color.' + animal.fiberColor)}</Definition>
                    <Definition title="Geburtstag:">{animal.dateOfBirth?.toLocaleString() ?? 'N/A'}</Definition>
                    <Definition title="Rasse:">{t('breed.' + animal.breed)}</Definition>
                </View>

                <PdfHeading>Identifikationsnummern</PdfHeading>

                <View>
                    {animal.panonIdentifier.animalIdentifiers.length
                        ? animal.panonIdentifier.animalIdentifiers.map(ai => (
                            <Definition key={ai.type + ai.position + ai.value}
                                        title={`${t('identifier.type.' + ai.type)} ${t(ai.position)}`}>
                                {ai.value}
                            </Definition>
                        ))
                        : <Text>N/A</Text>}
                </View>

                {!!pedigree && <>
                    <PdfHeading>Stammbaum</PdfHeading>

                    <View>
                        <Definition title="State:">{dam ? dam.fullName : 'unbekannt'}</Definition>
                        <Definition title="Deckhengst:">{sire ? sire.fullName : 'unbekannt'}</Definition>
                    </View>
                </>
                }

                <View fixed style={styles.footer}>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{marginRight: 'auto'}}>
                            Quelle: <Link src="https://herds.alpacuna.com/">https://herds.alpacuna.com</Link>
                        </Text>

                        <Text style={{textAlign: 'left'}}
                              render={({pageNumber, totalPages}) => `Seite ${pageNumber} / ${totalPages}`}/>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const AnimalPdf = ({match}: RouteComponentProps<{ panonId: string }>) => {
    const {panonId} = match.params;
    const {animal} = useAnimal(panonId, true);
    const history = useHistory();

    const {data: pedigree} = useQuery({
        queryKey: ['pedigree', animal?.id],
        queryFn: () => animal ? PedigreeService.loadPedigree(animal) : null,
        suspense: true,
    });

    if (!animal) {
        return null;
    }

    if (!pedigree) {
        return null;
    }

    const handleBackButton = () => history.push({
        pathname: RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: animal.panonIdentifier.id})
    });

    const document = <AnimalDocument animal={animal} pedigree={pedigree}/>;

    return (
        <Stack direction="column" spacing={2} margin={2}>
            <Stack direction="row" spacing={1}>
                <Button variant="outlined" startIcon={<ArrowBack/>} onClick={handleBackButton}>
                    Zurück
                </Button>
                <PDFDownloadLink document={document}
                                 fileName={`${animal.herdCode}_${animal.name}.pdf`}>
                    {({blob, url, loading, error}) => (
                        <Button startIcon={loading ? <Downloading/> : <FileDownload/>} variant="contained" disabled={loading}>
                            {loading ? 'PDF wird generiert' : 'Download PDF'}
                        </Button>
                    )}
                </PDFDownloadLink>
            </Stack>
            <PDFViewer width="100%" height="640">
                {document}
            </PDFViewer>
        </Stack>
    );
};

export default withRouter(AnimalPdf);
