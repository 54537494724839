// @flow
import React, {FunctionComponent} from 'react';
import {
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText, Stack,
    SvgIcon
} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import PregnancyDateRange from "./pregnancy-date-range";
import DeleteIcon from '@mui/icons-material/Delete';
import RouteService from "../../../services/route-service";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {PregnancyListJson} from "../../../api/generated/medical-rest";
import {ParentType, ParentTypeValues} from "../../../api/generated/herds-pedigree";
import {ErrorBox} from "../../Common/error-box";
import {usePartners} from "./hooks/use-partners";
import {DateTime} from "luxon";
import {getPregnancyState, PregnancyState} from "./pregnancy-utils";
import {EventBusy, Flare, SportsScoreOutlined} from "@mui/icons-material";
import {ReactComponent as MatingDateSvg} from "../../../icons/mating_date.svg"

type Props = {
    pregnancies: PregnancyListJson[],
    baseParentKind: ParentType,
    renderEndDate?: boolean,
    onDelete?: (pregnancyId: string) => void
};

type PregnancyListItemProps = {
    pregnancy: PregnancyListJson,
    partner: JsonAnimal | undefined,
    renderEndDate: boolean,
    onDelete?: (pregnancyId: string) => void
}


const getSecondary = (renderEndDate: boolean, pregnancy: PregnancyListJson) => {
    if (!pregnancy.startDate) {
        const daysAgo = Math.round(DateTime.now().diff(pregnancy.matingDate).as("days"));
        const suffix = pregnancy.actualEndDate ? '(erfolglos)' : `(vor ${daysAgo} Tagen)`;
        return <>gedeckt am {pregnancy.matingDate.toLocaleString()} {suffix}</>
    }

    const duration = renderEndDate ? <PregnancyDateRange pregnancy={pregnancy}/> : pregnancy.startDate.toLocaleString()
    if (pregnancy.matingDate) {
        return <>{duration}, gedeckt am {pregnancy.matingDate.toLocaleString()}</>;
    }
    return duration;
};

const getPregnancyIcon = (pregnancy: PregnancyListJson) => {
    const pregnancyState = getPregnancyState(pregnancy);
    switch (pregnancyState) {
        case PregnancyState.PREGNANT: return <Flare />;
        case PregnancyState.MATED: return <SvgIcon><MatingDateSvg /></SvgIcon>;
        case PregnancyState.UNSUCCESSFUL: return <EventBusy />;
        case PregnancyState.COMPLETED: return <SportsScoreOutlined />;
        default: return null;
    }
}

const PregnancyListItem: FunctionComponent<PregnancyListItemProps> = ({partner, pregnancy, renderEndDate, onDelete = null}) => {
    const partnerName = partner ? `${partner.herdCode} ${partner.name}` : '';
    const secondary = getSecondary(renderEndDate, pregnancy);

    const icon = getPregnancyIcon(pregnancy);

    return (
        <ListItem>
            <ListItemButton component={RouterLink} to={{pathname: RouteService.expand(RouteService.PREGNANCY_DETAILS, {pregnancyId: pregnancy.id})}}>
                <Stack spacing={2} direction="row" alignItems="center">
                    {icon}
                    <ListItemText primary={partnerName} secondary={secondary}/>
                </Stack>
            </ListItemButton>
            {onDelete !== undefined && onDelete !== null &&
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => onDelete(pregnancy.id)}
                    size="large">
                    <DeleteIcon/>
                </IconButton>
            </ListItemSecondaryAction>
            }
        </ListItem>
    );

}

const PregnancyList: FunctionComponent<Props> = ({pregnancies, baseParentKind, renderEndDate = true, onDelete}) => {

    const { partners, isLoading, isError } = usePartners(pregnancies, baseParentKind);

    if (isLoading) {
        return <LinearProgress />;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Trächtigkeiten.</ErrorBox>;
    }

    if (!partners) {
        return <ErrorBox>Trächtigkeiten können nicht angezeigt werden.</ErrorBox>;
    }

    return (
        <List>
            {pregnancies.map((p) => {
                const partner = partners.get(baseParentKind === ParentTypeValues.DAM ? (p.fatherAnimalId ?? '') : p.motherAnimalId);
                return <PregnancyListItem key={p.id} renderEndDate={renderEndDate} partner={partner} pregnancy={p} onDelete={onDelete}/>;
            }).filter(Boolean)}
        </List>
    );
};

export default PregnancyList
