import React, {PropsWithChildren, ReactElement, useState} from 'react';
import {Tab, Tabs} from "@mui/material";
import {TabPanel} from "./tab-panel";

type TabbedProps = {
    label: string;
};

const Tabbed = ({initialTab = 0, children}: { initialTab?: number, children: ReactElement<TabbedProps> | ReactElement<TabbedProps>[] }) => {
    const [activeTab, setActiveTab] = useState(initialTab);
    return (
        <>
            <Tabs value={activeTab} onChange={(event: unknown, index: number) => setActiveTab(index)}>
                {React.Children.map(children, (child, index) => (
                    <Tab label={child.props.label}></Tab>
                ))}
            </Tabs>

            {React.Children.map(children, (child, index) => (
                <TabPanel index={index} value={activeTab}>
                    {child}
                </TabPanel>
            ))}
        </>
    );
};

Tabbed.Tab = ({children}: PropsWithChildren<TabbedProps>) => <>{children}</>;

export default Tabbed;