import React, {PropsWithChildren} from "react";

export const TabPanel = (props: PropsWithChildren<{ index: number; value: number; }>) => {
    const {children, value, index} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {value === index && children}
        </div>
    );
};