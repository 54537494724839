import {Avatar, ListItem, ListItemAvatar, Skeleton} from "@mui/material";
import React from "react";

export const LoadingAvatarListItem = () => (
    <ListItem>
        <ListItemAvatar><Avatar/></ListItemAvatar>
        <Skeleton width="100%"/>
    </ListItem>
);

