import {AnimalRecordStatus} from "../../../api/generated/herd-animal";
import {useQuery} from "react-query";
import {AnimalService} from "../../../services/animal-service";

export const useHerd = (recordStatus: AnimalRecordStatus) => {
    const {data} = useQuery({
        queryKey: ['herd', recordStatus],
        queryFn: async () => (await AnimalService.loadHerd('all')).filter(a => a.recordStatus === recordStatus),
        suspense: true,
    });

    return {herd: data};
};
