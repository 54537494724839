import PropTypes from 'prop-types'
import AuthService from "../../services/auth-service";
import React, {FunctionComponent} from "react";


interface RenderOnRoleProperties {
    roles: string[];
}

const RenderOnRole: FunctionComponent<RenderOnRoleProperties>
    = ({roles, children}) => AuthService.hasRole(roles)
    ? <>{children}</>
    : null;

RenderOnRole.propTypes = {
    roles: PropTypes.array.isRequired,
}

export default RenderOnRole