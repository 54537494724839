// @flow
import React, {FunctionComponent} from 'react';
import FlexCard from "./flex-card";
import {RegistrationsController$RegistrationJson} from "../../api/generated/registry-rest";
import AnimalRegistrationsList from "../Registry/animal-registrations-list";

interface ExistingRegistrationCardProps {
    existingRegistrations: RegistrationsController$RegistrationJson[]
}

const ExistingRegistrationsCard: FunctionComponent<ExistingRegistrationCardProps> = ({existingRegistrations}) => {
    return (
        <FlexCard title="Bereits registriert bei">
           <AnimalRegistrationsList animalRegistrations={existingRegistrations} withDivider={true} />
        </FlexCard>
    );
};

export default ExistingRegistrationsCard