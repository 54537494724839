// @flow
import React, {FunctionComponent} from 'react';
import AnimalSearchResult from "../../api/dtos/animal-search-result";
import TabularTextCard from "../AnimalReview/tabular-text-card";

interface AnimalTabularTextCardProps {
    animalSearchResult: AnimalSearchResult,
    handleClick?: (clickedResult: AnimalSearchResult) => void,
    clickable?: boolean
}

const AnimalSearchResultCard: FunctionComponent<AnimalTabularTextCardProps> = ({animalSearchResult, handleClick, clickable}) => {
    const transformSearchResult = (searchResult: AnimalSearchResult): Map<string, any> => {
        const displayPairs: Map<string, any> = new Map();
        displayPairs.set("Geburtsdatum", searchResult.dateOfBirth ? searchResult.dateOfBirth.toLocaleString() : "n.a.");
        displayPairs.set("Rasse", searchResult.breed);
        displayPairs.set("Farbe", searchResult.fiberColor );
        displayPairs.set("Chipnummern", searchResult.panonId.animalIdentifiers.map(aId => aId.value).join(", "));
        displayPairs.set("Register", searchResult.registries.length > 0 ? searchResult.registries.map(sr => sr.name).join(", ") : []);
        return displayPairs;
    }


    return clickable && handleClick ?
        (<TabularTextCard clickable={clickable} onClick={() => handleClick(animalSearchResult)} title={animalSearchResult.herdCode + " " + animalSearchResult.name}
                          displayPairs={transformSearchResult(animalSearchResult)}/>) :
        (<TabularTextCard clickable={clickable} title={animalSearchResult.herdCode + " " + animalSearchResult.name} displayPairs={transformSearchResult(animalSearchResult)}/>);
};

export default AnimalSearchResultCard;