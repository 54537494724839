import {Sex, SexValues} from "../../api/generated/herd-animal";
import {Female, Male} from "@mui/icons-material";
import React, {PropsWithChildren} from "react";
import {Badge} from "@mui/material";

const getSexIcon = (sex: Sex) => {
    switch (sex) {
        case SexValues.MALE:
            return <Male/>;
        case SexValues.FEMALE:
            return <Female/>;
        default:
            return null;
    }
};

export const SexBadge = ({sex, children}: Readonly<PropsWithChildren<{ sex: Sex }>>) => (
    <Badge badgeContent={getSexIcon(sex)}>{children}</Badge>
);