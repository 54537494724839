// @flow
import React, {FunctionComponent} from 'react';
import {Alert, Button, Snackbar} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useGlobalSnackbarStore} from "../../stores/global-snackbar-store";


const GlobalSnackbar: FunctionComponent = () => {
    const {message, expandedRoute, severity, show, hide} =  useGlobalSnackbarStore((state) => state);

    const history = useHistory();

    const handleSnackbarClose = async () => {
        hide();
        if (expandedRoute) {
            history.push(expandedRoute);
        } else if(expandedRoute === "history-back") {
            history.goBack();
        }
    }

    return (
        <Snackbar
            open={show}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
            action={
                <Button color="secondary" size="small" onClick={handleSnackbarClose}>
                    OK
                </Button>}
        >
            <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default GlobalSnackbar