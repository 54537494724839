import {CreateHelpArticle, HelpApi, HelpArticle} from "../api/generated/herds-support";
import ApiConfig from "../api/config/api-config";
import {Promise} from "cypress/types/cy-bluebird";


export interface IHelpService {
    search(searchTerm: string): Promise<HelpArticle[]>;

    getById(id: string): Promise<HelpArticle>;

    create(helpArticleCreate: CreateHelpArticle): Promise<string>;

    update(helpArticle: HelpArticle): Promise<boolean>;

    delete(articleId: string): Promise<boolean>;
}

class HelpServiceImpl implements IHelpService {

    private _helpApi: HelpApi

    constructor(helpApi: HelpApi) {
        this._helpApi = helpApi;
    }

    async search(searchTerm: string): Promise<HelpArticle[]> {
        return (await this._helpApi.getAllHelpArticles(searchTerm)).items;
    }

    async update(helpArticle: HelpArticle): Promise<boolean> {
        return (await this._helpApi.updateHelpArticle(helpArticle.id, helpArticle)).info === "updated";
    }

    async create(helpArticleCreate: CreateHelpArticle): Promise<string> {
        return (await this._helpApi.createHelpArticle(helpArticleCreate)).id;
    }

    async delete(articleId: string): Promise<boolean> {
        await this._helpApi.deleteHelpArticle(articleId);
        return true;
    }

    async getById(id: string): Promise<HelpArticle> {
        return this._helpApi.getHelpArticleById(id);
    }

}


export const HelpService: IHelpService = new HelpServiceImpl(new HelpApi(new ApiConfig()));
