// @flow
import React, {FunctionComponent} from 'react';
import {Fab, Stack, SxProps} from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import {Link as RouterLink, useHistory} from 'react-router-dom';
import RenderOnRole from "../Permissions/render-on-role";
import RouteService from "../../services/route-service";
import RegistrySearchField from "./registry-search-field";
import {PANON_ADMIN} from "../Permissions/Roles";

const FindRegistry: FunctionComponent<Props> = (props: Props) => {

    const history = useHistory();

    const styles = {
        findRegistriesLayout: {
            margin: 1
        } as SxProps,
        fab: {
            position: "fixed",
            bottom: 5,
            right: 5,
        }
    }

    return (
        <Stack sx={styles.findRegistriesLayout}>
            <RegistrySearchField onSearchResultClick={(registry) => history.push(RouteService.expand(RouteService.REGISTRY_ADMINISTRATION_DETAILS, {id: registry.id}))}/>
            <RenderOnRole roles={[PANON_ADMIN]}>
                <Fab color="primary" variant="extended" sx={styles.fab} component={RouterLink} to={{pathname: RouteService.REGISTRY_ADMINISTRATION_CREATE}}>
                    <AddIcon/>
                    Register
                </Fab>
            </RenderOnRole>
        </Stack>
    );
};

type Props = {};

export default FindRegistry
