import {Widget} from "../Common";
import {Alert, LinearProgress} from "@mui/material";
import React from "react";
import TabularTextView from "./Common/tabular-text-view";
import {BreederService} from "../../services/breeder-service";
import {useQuery} from "react-query";

type OwnerWidgetProps = { ownerId: string };

const OwnerWidgetContent = ({ownerId}: OwnerWidgetProps) => {
    const {data: breederDetails, isLoading, isError} = useQuery(ownerId, () => BreederService.getDetails(ownerId));

    if (isLoading) {
        return (
            <LinearProgress/>
        );
    }

    if (isError || !breederDetails) {
        return (
            <Alert severity="error">Fehler beim Laden der Besitzerdaten.</Alert>
        );
    }

    const displayPairs = new Map([
        ['Benutzername', breederDetails.username],
        ['Herdencode', breederDetails.herdCode],
    ]);

    return (
        <TabularTextView displayPairs={displayPairs}/>
    );
};

export const OwnerWidget = ({ownerId}: OwnerWidgetProps) => {
    return (
        <Widget displayName="Besitzer">
            <OwnerWidgetContent ownerId={ownerId} />
        </Widget>
    );
};