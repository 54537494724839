import React from 'react';
import {Box, Button, ButtonGroup, Typography} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import AuthService from "../../services/auth-service";
import AvatarButton from "./avatar-button";
import RouteService from "../../services/route-service";

const Login: React.FC = () => {
    return AuthService.isLoggedIn()
        ? <AvatarButton username={AuthService.getUsername()}/>
        : <Box>
            <ButtonGroup variant="contained">
                <Button color="primary" onClick={() => AuthService.doLogin()}>
                    <Typography fontWeight="bold">Login</Typography>
                </Button>
                <Button component={RouterLink} to={RouteService.BREEDER_SIGNUP}
                        sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <Typography variant="caption">oder Registrieren</Typography>
                </Button>
            </ButtonGroup>
        </Box>
};

export default Login;
