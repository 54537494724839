// @flow
import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Divider, IconButton, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import BreederList from "../Common/breeder-list";
import RenderOnRole from "../Permissions/render-on-role";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import {RegistryDto, RegistryDto$BreederRef as BreederRef} from "../../api/generated/registry-service";
import {RegistryService, RegistryUserType} from "../../services/registry-service";
import RouteService from "../../services/route-service";
import AuthService from "../../services/auth-service";
import DeleteIcon from "@mui/icons-material/Delete";
import {BreederSearchResult} from "../../api/generated/herd-breeder";
import {PANON_ADMIN, REGISTRY_WARDEN} from "../Permissions/Roles";

type Props = {};

interface RegistryAdministrationRouteParams {
    id: string
}

const refsToBreederListDtoPlaceholder = (breederRefs: BreederRef[]) => breederRefs.map(ref => ({id: ref.id, username: "", email: "", herdCode: "", farmName: ref.name, website: "", owners:[]}));

const MembersList = ({members, handleRemoveMember}: {
    members: BreederRef[],
    handleRemoveMember?: (member: BreederRef) => void
}) =>
    <>
        {members.map(member => (
                <Fragment key={member.id}>
                    <ListItem secondaryAction={
                        handleRemoveMember && <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveMember(member)}>
                            <DeleteIcon/>
                        </IconButton>
                    }>
                        <ListItemText primary={member.name} secondary={member.id}/>
                    </ListItem>
                    <Divider variant="middle" component="li"/>
                </Fragment>
            )
        )}
    </>;

const RegistryAdministrationDetails: FunctionComponent<Props> = () => {
    const isWarden = AuthService.hasRole([REGISTRY_WARDEN, PANON_ADMIN]);
    const isAdmin = AuthService.hasRole([PANON_ADMIN]);

    const history = useHistory();
    const registryId = useParams<RegistryAdministrationRouteParams>().id;
    const [registry, setRegistry] = useState<RegistryDto>(null as unknown as RegistryDto);
    const [open, setOpen] = React.useState(false);
    const [members, setMembers] = React.useState<BreederRef[]>([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleActionClick = (userType: RegistryUserType) => {
        handleClose();
        history.push(RouteService.expand(RouteService.REGISTRY_ADMINISTRATION_ADD_WILDCARD_USERTYPE, {id: registryId, userType: userType.toString()}));
    }

    useEffect(() => {
        const initialize = async () => {
            setRegistry(await RegistryService.loadById(registryId));
            setMembers(await RegistryService.listMembers(registryId));
        }

        void initialize();
    }, [registryId])

    const handleUnassignAdmin = isAdmin
        ? async (breeder: BreederSearchResult) => {
            await RegistryService.unassignBreeder(registryId, breeder.id, RegistryUserType.ADMIN);
            setRegistry({...registry, admins: registry.admins.filter(admin => admin.id !== breeder.id)});
        }
        : undefined;

    const handleUnassignWarden = isAdmin
        ? async (breeder: BreederSearchResult) => {
            await RegistryService.unassignBreeder(registryId, breeder.id, RegistryUserType.WARDEN);
            setRegistry({...registry, wardens: registry.wardens.filter(warden => warden.id !== breeder.id)});
        }
        : undefined;

    const handleRemoveMember = isWarden
        ? async (member: BreederRef) => {
            await RegistryService.unassignBreeder(registryId, member.id, RegistryUserType.MEMBER);
            setMembers(members.filter(memberRef => memberRef.id !== member.id));
        }
        : undefined;

    return registry == null ? null : (
        <Stack spacing={3} justifyContent="flex-start" alignItems="left">
            <Typography variant="h3" align="center">{registry.name}</Typography>
            <Typography variant="h5" align="center">Administratoren</Typography>
            {registry.admins.length > 0
                ? <BreederList breeders={refsToBreederListDtoPlaceholder(registry.admins)}
                               onListItemDelete={handleUnassignAdmin}/>
                : <Typography align="center" variant="body1">Noch keine Administratoren vorhanden.</Typography>}
            <Typography variant="h5" align="center">Reviewers</Typography>
            {registry.wardens.length > 0
                ? <BreederList breeders={refsToBreederListDtoPlaceholder(registry.wardens)}
                               onListItemDelete={handleUnassignWarden}/>
                : <Typography align="center" variant="body1">Noch keine Reviewer vorhanden.</Typography>}
            <Typography variant="h5" align="center">Mitglieder</Typography>
            <MembersList members={members} handleRemoveMember={handleRemoveMember} />
            <RenderOnRole roles={[REGISTRY_WARDEN, PANON_ADMIN]}>
                <SpeedDial
                    ariaLabel="SpeedDial tooltip example"
                    sx={{position: 'absolute', bottom: 16, right: 16}}
                    icon={<SpeedDialIcon/>}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                >
                    {isWarden && <SpeedDialAction
                        key="Mitglied"
                        icon={<PersonAddAlt1OutlinedIcon/>}
                        tooltipTitle="Mitglied"
                        tooltipOpen
                        onClick={() => handleActionClick(RegistryUserType.MEMBER)}
                    />}

                    {isAdmin && <SpeedDialAction
                        key="Reviewer"
                        icon={<RateReviewOutlinedIcon/>}
                        tooltipTitle="Reviewer"
                        tooltipOpen
                        onClick={() => handleActionClick(RegistryUserType.WARDEN)}
                    />}

                    {isAdmin && <SpeedDialAction
                        key="Administrator"
                        icon={<SupervisorAccountOutlinedIcon/>}
                        tooltipTitle="Administrator"
                        tooltipOpen
                        onClick={() => handleActionClick(RegistryUserType.ADMIN)}
                    />}

                </SpeedDial>
            </RenderOnRole>
        </Stack>
    );
};

export default RegistryAdministrationDetails
