import React, {useEffect} from 'react';
import {Button, Container, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from 'react-i18next';
import {HelpArticle} from "../../api/generated/herds-support";

// HelpArticleEditor Component
const HelpArticleEditor: React.FC<{ article?: HelpArticle; onSave: (article: HelpArticle) => void }> = ({ article, onSave }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm<HelpArticle>({
        defaultValues: {
            title: article?.title ?? '',
            summary: article?.summary ?? '',
            fullUrl: article?.fullUrl ?? '',
            videoUrl: article?.videoUrl ?? '',
        },
    });

    useEffect(() => {
        const resetTo: HelpArticle = article ?? { fullUrl: "", id: "", summary: "", title: "", videoUrl: "" }
        reset(resetTo);
    }, [article, reset]);

    const onSubmit = (data: HelpArticle) => {
        const newArticle: HelpArticle = {
            ...data,
            id: article ? article.id : uuidv4(),
        };
        onSave(newArticle);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>{article ? t('helpArticleEditor.editTitle') : t('helpArticleEditor.createTitle')}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('helpArticleEditor.titleLabel')}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="summary"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('helpArticleEditor.summaryLabel')}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                        />
                    )}
                />
                <Controller
                    name="fullUrl"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('helpArticleEditor.fullUrlLabel')}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="videoUrl"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('helpArticleEditor.videoUrlLabel')}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
                    {t('helpArticleEditor.saveButton')}
                </Button>
            </form>
        </Container>
    );
};

export default HelpArticleEditor;
