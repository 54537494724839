// @flow
import React, {FunctionComponent} from 'react';
import {ListItem, ListItemText} from "@mui/material";
import {RegistryDto} from "../../api/generated/registry-service";

interface AnimalRegistrationListItemProps {
    registrationListDto: RegistryDto
};
const AnimalRegistrationListItem: FunctionComponent<AnimalRegistrationListItemProps> = ({registrationListDto}) => {
    return (
        <ListItem>
            <ListItemText primary={registrationListDto.name} />
        </ListItem>
    );
};

export default AnimalRegistrationListItem