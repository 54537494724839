import ApiConfig from "../api/config/api-config";
import {TagApi} from "../api/generated/animal-controller";
import {TagAnimalDto} from "../api/generated/rest-dto";
import {Result} from "../api/generated/lib-rest";

let tagApi = new TagApi(new ApiConfig());

const setTags = async (tagAnimal: TagAnimalDto): Promise<Result> => {
    return await tagApi.set(tagAnimal);
}

const loadTags = async (): Promise<string[]> => {
    return (await tagApi.herdTags()).items;
}

const TagService = {
    setTags,
    loadTags
};

export default TagService;