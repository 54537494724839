import React, {FunctionComponent, Suspense, useState} from 'react';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import {Route, Switch} from "react-router-dom";
import Herd from "../pages/herd";
import AnimalDashboard from "./AnimalDashboard";
import {
    Box,
    CircularProgress,
    createTheme,
    PaletteColor,
    PaletteColorOptions,
    Stack,
    SxProps,
    Theme,
    ThemeProvider,
    Toolbar,
    useMediaQuery
} from "@mui/material";
import NoMatch from "./Routing/no-match";
import ApplicationBar from "./application-bar";
import NavigationMenu from "./navigationMenu";
import RolesRoute from "./Permissions/roles-route";
import {LocalizationProvider} from "@mui/x-date-pickers";
import PregnancyDetails from "./AnimalDashboard/Pregnancy/pregnancy-details";
import ActionsMenu from "./Common/actions-menu";
import FindRegistry from "./Registry/find-registry";
import CreateRegistry from "./Registry/create-registry";
import RegistryAdministrationDetails from "./Registry/registry-administration-details";
import AddRegistryUser from "./Registry/add-registry-user";
import {RegistryUserType} from "../services/registry-service";
import RouteService from "../services/route-service";
import JoinRegistry from "./Registry/join-registry";
import SelectRegistryForAnimalRequest from "./Registry/select-registry-for-animal-request";
import ReviewList from "./AnimalReview/review-list";
import AnimalReview from "./AnimalReview/animal-review";
import SimilarityComparison from "./AnimalReview/similarity-comparison";
import RegistrationsSearchPage from "./Registry/registrations-search-page";
import RegisteredAnimalDetails from "../pages/registered-animal-details";
import AnimalCreate from "../pages/animal-create";
import PregnancyCreate from "../pages/pregnancy-create";
import SignupBreeder from "../pages/signup-breeder";
import TagEditor from "../pages/tag-editor";
import TreatmentEditor from "../pages/treatment-editor";
import {blue, orange, pink} from "@mui/material/colors";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import AnimalAncestry from '../pages/ancestry-change';
import AdminAccountsLink from "../pages/admin-accounts-link";
import {QueryClient, QueryClientProvider} from 'react-query';
import AdminAccountsLinkEditor from "../pages/admin-accounts-link-editor";
import GlobalSnackbar from "./Common/global-snackbar";
import {BREEDER, PANON_ADMIN, REGISTRY_WARDEN} from "./Permissions/Roles";
import AuthService from "../services/auth-service";
import {LoginCard} from "./login-card";
import Breeders from "../pages/breeders";
import BreederDetails from "../pages/breeder-details";
import {HerdPregnancies} from "../pages/herd-pregnancies";
import {Dashboard} from "./Home/dashboard";
import MatingCreate from '../pages/mating-create';
import AnimalPdf from "./AnimalDashboard/Pdf/AnimalPdf";
import PendingRegistrations from "./Registry/pending-registrations";
import HelpPage from "../pages/help-page";
import HelpEditPage from "../pages/help-edit-page";
import Farm from "../pages/farm";


declare module '@mui/material/styles' {
    interface Palette {
        male: PaletteColor;
        female: PaletteColor;
    }

    interface PaletteOptions {
        male: PaletteColorOptions;
        female: PaletteColorOptions;
    }

    interface Theme {
        drawerWidth: number;
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        drawerWidth?: number;
    }
}

const {palette} = createTheme();

const customTheme: Theme = createTheme({
    palette: {
        male: palette.augmentColor({color: blue}),
        female: palette.augmentColor({color: pink}),
        warning: palette.augmentColor({color: orange})
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: "standard"
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard"
            }
        }
    },
    drawerWidth: 240
});

const LoadingFallback = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
        <CircularProgress/>
    </Box>
);

const styles: Record<string, SxProps> = {
    root: {
        display: 'flex'
    },
    drawer: {
        flexShrink: {sm: 0},
        width: "drawerWidth"
    },
    permanentNavigationMenu: {
        width: customTheme.drawerWidth,
        overflowX: "hidden",
        overflowY: "auto"}

} as const;

const contentStyles: SxProps<Theme> = {
    width: {
        xs: "100%",
        sm: `calc(100% - ${customTheme.drawerWidth}px)`
    },
    ml: {
        sm: `${customTheme.drawerWidth}px`
    },
    flexGrow: 1
} as const;

const ThemedApp = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [overflowMenuAnchor, setOverflowMenuAnchor] = useState<HTMLElement | null>(null);

    const handleDrawerClose = () => setMobileOpen(false);
    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const permanentDrawer = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <div translate="no">
            <CssBaseline/>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
                <Box sx={styles.root}>
                    <ApplicationBar drawerWidth={customTheme.drawerWidth} onDrawerClick={handleDrawerToggle}
                                    onOverflowMenuClick={setOverflowMenuAnchor}/>
                    <ActionsMenu anchor={overflowMenuAnchor} onClose={() => setOverflowMenuAnchor(null)}/>
                    {permanentDrawer
                        ? <Drawer sx={styles.drawer} variant="permanent" open>
                            <NavigationMenu sx={styles.permanentNavigationMenu} onClick={handleDrawerClose}/>
                        </Drawer>
                        : <Drawer sx={styles.drawer} variant="temporary" anchor="left" open={mobileOpen}
                                  onClose={handleDrawerToggle} ModalProps={{keepMounted: true}}>
                            <NavigationMenu sx={{width: customTheme.drawerWidth}} onClick={handleDrawerClose}/>
                        </Drawer>}
                    <Box component="main" sx={contentStyles}>
                        <Toolbar/>
                        {!AuthService.isLoggedIn()
                            ? <Stack direction="column" alignItems="center" justifyContent="center">
                                <LoginCard />
                            </Stack>
                            : <Suspense fallback={<LoadingFallback/>}>
                                <Switch>
                                    <RolesRoute roles={[PANON_ADMIN]} path={RouteService.ADMIN_LINK_ACCOUNT_WITH_BREEDER_EDITOR}>
                                        <AdminAccountsLinkEditor/>
                                    </RolesRoute>
                                    <RolesRoute roles={[PANON_ADMIN]} path={RouteService.ADMIN_LINK_ACCOUNT_WITH_BREEDER}>
                                        <AdminAccountsLink/>
                                    </RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.HERD_GROUP}><Herd/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.HERD_PREGNANCIES_LIST}><HerdPregnancies/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.ANIMAL_CREATE}><AnimalCreate/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.REGISTERED_ANIMAL_DETAILS}><RegisteredAnimalDetails/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.ANIMAL_DETAILS_PDF}><AnimalPdf/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.ANIMAL_DETAILS}><AnimalDashboard/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.ANIMAL_CHANGE_ANCESTRY}><AnimalAncestry/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.ANIMAL_REGISTRY_APPLICATION_SELECT}><SelectRegistryForAnimalRequest/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.MATING_CREATE}><MatingCreate/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.PREGNANCY_CREATE}><PregnancyCreate/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.PREGNANCY_DETAILS}><PregnancyDetails/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.REGISTRY_JOIN}><JoinRegistry/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.REGISTRY_SEARCH}><FindRegistry/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.REGISTRY_ANIMAL_SEARCH}><RegistrationsSearchPage/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.REGISTRY_MY_REGISTRATIONS_LIST}><PendingRegistrations/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.TAGS_EDIT}><TagEditor/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.TREATMENT_EDIT}><TreatmentEditor/></RolesRoute>
                                    <RolesRoute roles={[PANON_ADMIN]} path={RouteService.REGISTRY_ADMINISTRATION_CREATE}><CreateRegistry/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.REGISTRY_ADMINISTRATION_DETAILS}><RegistryAdministrationDetails/></RolesRoute>
                                    <RolesRoute roles={[PANON_ADMIN]} path={RouteService.REGISTRY_ADMINISTRATION_ADD_ADMIN}>
                                        <AddRegistryUser userType={RegistryUserType.ADMIN}/>
                                    </RolesRoute>
                                    <RolesRoute roles={[PANON_ADMIN]} path={RouteService.REGISTRY_ADMINISTRATION_ADD_WARDEN}>
                                        <AddRegistryUser userType={RegistryUserType.WARDEN}/>
                                    </RolesRoute>
                                    <RolesRoute roles={[PANON_ADMIN]} path={RouteService.REGISTRY_ADMINISTRATION_ADD_MEMBER}>
                                        <AddRegistryUser userType={RegistryUserType.MEMBER}/>
                                    </RolesRoute>
                                    <RolesRoute roles={[PANON_ADMIN]} path={RouteService.ADD_HELP_ARTICLE}><HelpEditPage/></RolesRoute>
                                    <RolesRoute roles={[PANON_ADMIN]} path={RouteService.EDIT_HELP_ARTICLE}><HelpEditPage/></RolesRoute>
                                    <RolesRoute roles={[REGISTRY_WARDEN]} path={RouteService.REGISTRY_ANIMAL_REVIEW_COMPARE_SIMILARITIES}><SimilarityComparison/></RolesRoute>
                                    <RolesRoute roles={[REGISTRY_WARDEN]} path={RouteService.REGISTRY_ANIMAL_REVIEW}><AnimalReview/></RolesRoute>
                                    <RolesRoute roles={[REGISTRY_WARDEN]} path={RouteService.REGISTRY_ANIMAL_REVIEW_LIST}><ReviewList/></RolesRoute>
                                    <RolesRoute roles={[BREEDER]} path={RouteService.BREEDER_BASE_DATA}><Farm /></RolesRoute>
                                    <Route path={RouteService.BREEDER_SIGNUP}><SignupBreeder/></Route>
                                    <Route path={RouteService.BREEDERS_LIST}><Breeders/></Route>
                                    <Route path={RouteService.BREEDER_DETAILS}><BreederDetails/></Route>
                                    <Route path={RouteService.HELP}><HelpPage /></Route>
                                    <Route path={RouteService.HOME}><Dashboard/></Route>
                                    <Route path={RouteService.CATCH_ALL}><NoMatch/></Route>
                                </Switch>
                            </Suspense>
                        }
                        <GlobalSnackbar/>
                    </Box>
                </Box>
            </LocalizationProvider>
        </div>
    );
};

const queryClient = new QueryClient();

const App: FunctionComponent = () => (
    <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={customTheme}>
            <ThemedApp/>
        </ThemeProvider>
    </QueryClientProvider>
);

export default App;
