// @flow
import React, {Suspense} from 'react';
import {Alert, List} from "@mui/material";
import RegistrationReviewService from "../../services/registration-review-service";
import ReviewRequestListItem from "./review-request-list-item";
import {useQuery} from "react-query";
import {LoadingAvatarListItem} from "../Common/loading-avatar-list-item";
import {withErrorBoundary} from "react-error-boundary";
import {ErrorBox} from "../Common/error-box";
import Tabbed from '../Common/tabbed';
import {RegistrationRequestController$PendingRequestJson} from "../../api/generated/registry-rest";

const useReviewApplications = () => {
    const {data} = useQuery({
        queryKey: ['review-applications'],
        queryFn: async () => RegistrationReviewService.loadReviewRequestsByWarden(),
        suspense: true,
    });

    return {applications: data ?? []};
};

const ApplicationsList = ({applications}:{ applications: RegistrationRequestController$PendingRequestJson[] }) => (
    <List>
        {applications.map(a => <ReviewRequestListItem key={a.id} application={a}/>)}
    </List>
);

const ReviewListView = () => {
    const {applications} = useReviewApplications();

    if (!applications.length) {
        return <Alert severity="info">Keine Anfragen vorhanden</Alert>;
    }

    const pending = applications.filter(a => !a.rejectionTime);
    const rejected = applications.filter(a => a.rejectionTime);

    return (
        <Tabbed>
            <Tabbed.Tab label={`Aktiv (${pending.length})`}>
                <ApplicationsList applications={pending} />
            </Tabbed.Tab>
            <Tabbed.Tab label={`Abgelehnt (${rejected.length})`}>
                <ApplicationsList applications={rejected} />
            </Tabbed.Tab>
            <Tabbed.Tab label={`Alle (${applications.length})`}>
                <ApplicationsList applications={applications} />
            </Tabbed.Tab>
        </Tabbed>
    );
};

const LoadingTabsFallback = () => (
    <Tabbed>
        <Tabbed.Tab label="Alle (…)">
            <List><LoadingAvatarListItem/><LoadingAvatarListItem/><LoadingAvatarListItem/></List>
        </Tabbed.Tab>
    </Tabbed>
);

const ReviewList = () => (
    <Suspense fallback={<LoadingTabsFallback/>}>
        <ReviewListView/>
    </Suspense>
);

export default withErrorBoundary(
    ReviewList,
    {
        fallback: <ErrorBox>Fehler beim Rendern der Anfragen!</ErrorBox>
    }
);