import {FormControl, InputLabel, Select, SxProps, Theme} from "@mui/material";
import React, {FunctionComponent} from "react";

import {Controller} from "react-hook-form";

interface ReactHookFormSelectProps {
    name: string,
    label: string,
    control: any,
    defaultValue?: any,
    children: any,
    required?: boolean
    sx?: SxProps<Theme>
}

const ReactHookFormSelect: FunctionComponent<ReactHookFormSelectProps> = ({
                                                                              name,
                                                                              label,
                                                                              control,
                                                                              defaultValue,
                                                                              children,
                                                                              required = false,
                                                                              sx
                                                                          }) => {
    const labelId = `${name}-label`;
    const selectId = `${name}-id`;
    return (
        <Controller defaultValue={defaultValue} control={control} name={name} render={({field}) => (
            <FormControl required={required} sx={sx}>
                <InputLabel id={labelId}>{label}</InputLabel>
                <Select
                    labelId={labelId}
                    id={selectId}
                    {...field}
                >
                    {children}
                </Select>
            </FormControl>
        )}/>
    );
};
export default ReactHookFormSelect;