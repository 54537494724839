// @flow
import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {SearchField} from "../Common";
import {Divider, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {RegistryService} from "../../services/registry-service";
import {RegistryDto} from "../../api/generated/registry-service";

interface RegistrySearchFieldProps {
    onSearchResultClick: (registry: RegistryDto) => void
};

const RegistrySearchField: FunctionComponent<RegistrySearchFieldProps> = ({onSearchResultClick}) => {

    const [registries, setRegistries] = useState<RegistryDto[]>([]);
    const [registry, setRegistry] = useState<RegistryDto | null>(null);

    const findRegistrySuggestions = async (searchTerm: string) => {
        setRegistries(await RegistryService.findByName(searchTerm));
        return registries;
    }

    const prefillRegistriesSearchResult = async() => {
        const wardenRegistriesPromise = RegistryService.findAllWhereBreederIsWarden();
        const adminRegistriesPromise = RegistryService.findAllWhereBreederIsAdmin();
        const adminAndWardenRegistries = await Promise.all([wardenRegistriesPromise, adminRegistriesPromise]);
        const distinctRegistries: RegistryDto[] =  Array.from(new Map(adminAndWardenRegistries.flat().map(reg => [reg["id"], reg])).values());
        setRegistries(distinctRegistries);
    }

    useEffect(() => {
        prefillRegistriesSearchResult();
    }, []);

    return (
        <Fragment>
            <SearchField<RegistryDto> showSearchIcon={true} findSuggestions={findRegistrySuggestions} renderOption={(r) => r.name} optionUniqueKey={(r) => r.id} value={registry}
                                      onValueChange={setRegistry}/>
            <List>
                {registries.map(registry =>
                    <Fragment key={registry.id}>
                        <ListItem>
                            <ListItemButton onClick={() => onSearchResultClick(registry)}>
                                <ListItemText primary={registry.name}/>
                            </ListItemButton>
                        </ListItem>
                        <Divider variant="middle" component="li"/>
                    </Fragment>
                )}
            </List>
        </Fragment>
    );
};

export default RegistrySearchField
