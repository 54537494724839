import React, {useState} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Button, DialogActions, DialogContent, Stack} from "@mui/material";
import TreatmentType from "../../../api/dtos/treatment-type";
import TreatmentTypeSelectList from "../Common/treatment-type-select-list";


export type TreatmentFilterDialogProps = {
    open: boolean;
    onChange: (filterOptions: TreatmentFilterOptions) => void;
    onClose: () => void;
    defaultTypes?: TreatmentType[];
};

export type TreatmentFilterOptions = {
    includedTypes?: TreatmentType[];
    timestamp?: Date;
    note?: string;
    drug?:string;
};

const TreatmentFilterDialog = (props: TreatmentFilterDialogProps) => {
    const {onChange, onClose, open} = props;

    const [treatmentTypes, setTreatmentTypes] = useState(props.defaultTypes);

    const toggleType = (type: TreatmentType) => {
        const newSet = new Set(treatmentTypes);
        !newSet.delete(type) && newSet.add(type);
        setTreatmentTypes(Array.from(newSet.values()));
    };

    const handleFilterApply = () => onChange({includedTypes: treatmentTypes});

    return (
        <Dialog maxWidth="xs"
                fullWidth={true}
                onClose={onClose}
                aria-labelledby="label-assignment-dialog-title"
                open={open}>
            <DialogTitle id="simple-dialog-title">Behandlungen filtern</DialogTitle>
            <DialogContent>
                <Stack direction="row">
                    <Button onClick={() => setTreatmentTypes(Object.values(TreatmentType))}>Alle</Button>
                    <Button onClick={() => setTreatmentTypes([])}>Keine</Button>
                    <Button onClick={() => setTreatmentTypes(props.defaultTypes)}>Zurücksetzen</Button>
                </Stack>
                <TreatmentTypeSelectList types={treatmentTypes}
                                         availableOptions={Object.values(TreatmentType)}
                                         onTypeChange={toggleType}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleFilterApply}>Anwenden</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TreatmentFilterDialog;
