// @flow
import React, {FunctionComponent} from 'react';
import FlexCard from "./flex-card";
import TabularTextView from "../AnimalDashboard/Common/tabular-text-view";

interface TabularTextCardProps {
    title: string,
    displayPairs: Map<string, string>
    clickable?: boolean
    onClick?: () => void
}

const TabularTextCard: FunctionComponent<TabularTextCardProps> = ({title, displayPairs, clickable=false, onClick = () => {}}) => {

    return (
        <FlexCard clickable={clickable} onClick={onClick} title={title}>
            <TabularTextView displayPairs={displayPairs} />
        </FlexCard>
    );
};

export default TabularTextCard