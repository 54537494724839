// @flow
import React, {FunctionComponent} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

type ConfirmDialogProps = {
    title?: string
    cancelButtonText?: string
    okButtonText?: string
    onClose: () => void,
    onOk: () => void,
    onCancel?: () => void
    open: boolean,
};
const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
                                                               title = "Bitte bestätige deine Auswahl",
                                                               cancelButtonText = "Abbrechen",
                                                               okButtonText = "Ok",
                                                               onClose,
                                                               open,
                                                               onOk,
                                                               onCancel,
                                                               children
                                                           }) => {

    const handleAddClick = () => {
        onClose();
        onOk();
    }

    const handleCancelClick = () => {
        onClose();
        if (onCancel) {
            onCancel();
        }
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick}>{cancelButtonText}</Button>
                <Button onClick={handleAddClick} variant="contained">{okButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog