// @flow
import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {Divider, List, ListItem, ListItemButton, ListItemText, Stack, SxProps, Typography} from "@mui/material";
import {RegistryService, RegistryUserType} from "../../services/registry-service";
import ConfirmDialog from "../Common/confirm-dialog";
import {BreederService} from "../../services/breeder-service";
import RouteService from "../../services/route-service";
import OnCloseNavigationSnackbar from "../Common/on-close-navigation-snackbar";
import {RegistryDto} from "../../api/generated/registry-service";

const JoinRegistry: FunctionComponent<Props> = (props: Props) => {

    const styles = {
        findRegistriesLayout: {
            margin: 1
        } as SxProps,
        fab: {
            position: "fixed",
            bottom: 5,
            right: 5,
        }
    };

    const [registries, setRegistries] = useState<RegistryDto[]>([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedRegistry, setSelectedRegistry] = useState<RegistryDto | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const fetchRegistries = async () => {
        const registries = await RegistryService.findAllWhereBreederIsNotMember();
        setRegistries(registries);
    }

    useEffect(() => {
        fetchRegistries();
    }, [])

    const openConfirmationDialog = (registry: RegistryDto) => {
        setSelectedRegistry(registry);
        setConfirmDialogOpen(true);
    }

    const handleOk = async () => {
        const breederInfo = await BreederService.info();
        if(selectedRegistry){
            await RegistryService.assignBreeder(selectedRegistry.id, breederInfo.id, RegistryUserType.MEMBER);
            setRegistries(registries.filter(r => r.id !== selectedRegistry.id));
            setSelectedRegistry(null);
            setSnackbarOpen(true);
        } else {
            //TODO: Error message on join fail!
        }
        setConfirmDialogOpen(false);
    }

    return (
        <Stack sx={styles.findRegistriesLayout}>
            <ConfirmDialog onClose={() => setConfirmDialogOpen(false)} onOk={handleOk} okButtonText="Beitreten" title="Diesem Register beitreten?" open={confirmDialogOpen}>
                <Typography variant="h5">{selectedRegistry ? selectedRegistry.name : ""}</Typography>
            </ConfirmDialog>
            <Typography variant="h3" align="center">Register beitreten</Typography>
            <List>
                {registries.map(registry =>
                    <Fragment key={registry.id}>
                        <ListItem>
                            <ListItemButton>
                                <ListItemText primary={registry.name} onClick={() => openConfirmationDialog(registry)}/>
                            </ListItemButton>
                        </ListItem>
                        <Divider variant="middle" component="li"/>
                    </Fragment>
                )}
            </List>
            <OnCloseNavigationSnackbar message="Register beigetreten!" triggerSnackbar={snackbarOpen} expandedRoute={RouteService.MEMBER_REGISTRIES} />
        </Stack>
    );
};

type Props = {};

export default JoinRegistry
