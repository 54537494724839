import {Avatar} from "@mui/material";
import React from "react";

const AVATAR_COLOR_PALETTE = [
    '#332288',
    '#117733',
    '#44aa99',
    '#88ccee',
    '#ddcc77',
    '#cc6677',
    '#aa4499',
    '#882255',
] as const;

const simpleStringHash = (str: string): number => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = (31 * hash + str.charCodeAt(i)) | 0;
    }
    return hash;
};

export const ColoredAvatar = ({id, name}: { id: string; name?: string; }) => {
    const hash = simpleStringHash(name ?? id);
    const bgcolor = AVATAR_COLOR_PALETTE[hash & 0x7];
    return <Avatar alt={name?.toUpperCase()} sx={{bgcolor}} src={id}/>;
};