import {BreederService} from "./breeder-service";
import {AssignRegistryAdminCommand, RegistryApi, RegistryListApi} from "../api/generated/registry-rest";
import ApiConfig from "../api/config/api-config";
import {RegistryDto, RegistryDto$BreederRef} from "../api/generated/registry-service";
import {CreateResult, Result} from "../api/generated/lib-rest";


export enum RegistryUserType {
    ADMIN = "admin",
    WARDEN = "warden",
    MEMBER = "member"
}

export interface IRegistryService {
    getUserTypeString(userType: RegistryUserType): string;

    assignBreeder(registryId: string, breederId: string, userType: RegistryUserType): Promise<Result>;

    unassignBreeder(registryId: string, breederId: string, userType: RegistryUserType): Promise<Result>;

    listMembers(registryId: string): Promise<RegistryDto$BreederRef[]>;

    findByName(registryName: string): Promise<RegistryDto[]>;

    findByMemberId(memberId: string): Promise<RegistryDto[]>;

    findByWardenId(wardenId: string): Promise<RegistryDto[]>;

    findAllWhereBreederIsWarden(): Promise<RegistryDto[]>;

    findByAdminId(adminId: string): Promise<RegistryDto[]>;

    findAllWhereBreederIsAdmin(): Promise<RegistryDto[]>;

    findAll(): Promise<RegistryDto[]>;

    findAllWhereBreederIsMember(): Promise<RegistryDto[]>;

    findAllWhereBreederIsNotMember(): Promise<RegistryDto[]>;

    createRegistry(name: string): Promise<CreateResult>;

    loadById(registryId: string): Promise<RegistryDto>;

    loadByIds(registryIds: string[]): Promise<RegistryDto[]>;
}

class RegistryServiceImpl implements IRegistryService {
    private _registryApi: RegistryApi;
    private _registryListApi: RegistryListApi;
    
    constructor(registryApi: RegistryApi, registryListApi: RegistryListApi) {
        this._registryApi = registryApi;
        this._registryListApi = registryListApi;
    }
    getUserTypeString(userType: RegistryUserType) {
        switch (userType) {
            case RegistryUserType.ADMIN:
                return "Administrator";
            case RegistryUserType.WARDEN:
                return "Reviewer";
            case RegistryUserType.MEMBER:
                return "Mitglied";
        }
    }

    async assignBreeder(registryId: string, breederId: string, userType: RegistryUserType) {
        const command: AssignRegistryAdminCommand = {userId: breederId};
        switch (userType) {
            case RegistryUserType.ADMIN:
                return await this._registryApi.assignAdmin(registryId, command);
            case RegistryUserType.WARDEN:
                return await this._registryApi.assignWarden(registryId, command);
            case RegistryUserType.MEMBER:
                return await this._registryApi.addMember(registryId, command);
        }
    }

    async unassignBreeder(registryId: string, breederId: string, userType: RegistryUserType) {
        switch (userType) {
            case RegistryUserType.ADMIN:
                return await this._registryApi.unassignAdmin(registryId, breederId);
            case RegistryUserType.WARDEN:
                return await this._registryApi.unassignWarden(registryId, breederId);
            case RegistryUserType.MEMBER:
                return await this._registryApi.removeMember(registryId, breederId);
        }
    }

    async listMembers(registryId: string): Promise<RegistryDto$BreederRef[]> {
        return (await this._registryApi.listMembers(registryId)).items;
    }

    async findByName(registryName: string): Promise<RegistryDto[]> {
        return (await this._registryListApi.list(registryName)).items;
    }

    async findByMemberId(memberId: string): Promise<RegistryDto[]> {
        return (await this._registryListApi.list(undefined, memberId)).items;
    }

    async findByWardenId(wardenId: string): Promise<RegistryDto[]> {
        return (await this._registryListApi.list(undefined, undefined, wardenId)).items;
    }

    async findAllWhereBreederIsWarden(): Promise<RegistryDto[]> {
        return await this.findByWardenId((await BreederService.info()).id);
    }

    async findByAdminId(adminId: string): Promise<RegistryDto[]> {
        return (await this._registryListApi.list(undefined, undefined, undefined, adminId)).items;
    }

    async findAllWhereBreederIsAdmin(): Promise<RegistryDto[]> {
        return await this.findByAdminId((await BreederService.info()).id);
    }

    async findAll(): Promise<RegistryDto[]> {
        return (await this._registryListApi.list()).items;
    }

    async findAllWhereBreederIsMember(): Promise<RegistryDto[]> {
        return await this.findByMemberId((await BreederService.info()).id);
    }

    async findAllWhereBreederIsNotMember(): Promise<RegistryDto[]> {
        const allRegPromise = this.findAll();
        const memberRegPromise = this.findAllWhereBreederIsMember();
        const [allRegistries, memberRegistries] = await Promise.all([allRegPromise, memberRegPromise]);
        const memberRegistriesIds = memberRegistries.map(mr => mr.id);
        return allRegistries.filter(r => !memberRegistriesIds.includes(r.id));
    }

    async createRegistry(name: string): Promise<CreateResult> {
        return await this._registryApi.newRegistry({name});
    }

    async loadById(registryId: string): Promise<RegistryDto> {
        return (await this._registryApi.details(registryId)).item;
    }

    async loadByIds(registryIds: string[]): Promise<RegistryDto[]> {
        return (await this._registryListApi.getBulkByIds(registryIds)).items;
    }
}

const apiConfig = new ApiConfig();
const registryApi = new RegistryApi(apiConfig);
const registryListApi = new RegistryListApi(apiConfig);

export const RegistryService: IRegistryService = new RegistryServiceImpl(registryApi, registryListApi);