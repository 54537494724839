// @flow
import React, {FunctionComponent} from 'react';
import AnimalSearchResult from "../../api/dtos/animal-search-result";
import {useHistory} from "react-router-dom";
import RouteService from "../../services/route-service";
import AnimalSearch from "../Common/animal-search";
import {Typography} from "@mui/material";

const RegistrationsSearchPage: FunctionComponent = () => {

    const history = useHistory();

    const handleCardClick = (animal: AnimalSearchResult) => {
        history.push(RouteService.expand(RouteService.REGISTERED_ANIMAL_DETAILS, {
            registrationStatus: "registered",
            panonId: animal.panonId.id
        }))
    }

    return (
        <AnimalSearch handleResultClick={handleCardClick} searchHerdAndPedigree={false} renderOnEmptyResult={<Typography> keine Ergebnisse</Typography>} />
    );
};

export default RegistrationsSearchPage