// @flow
import * as React from 'react';
import {Widget} from "../Common";
import RouteService from "../../services/route-service";
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {JsonAnimal} from "../../api/generated/rest-dto";
import {RegistrationsController$RegistrationJson} from "../../api/generated/registry-rest";
import AnimalRegistrationsList from "./animal-registrations-list";


interface RegistryWidgetProps {
    animal: JsonAnimal;
    animalRegistrations: RegistrationsController$RegistrationJson[]
}


const RegistryWidget: React.FC<RegistryWidgetProps> = ({animal, animalRegistrations}) => {
    const history = useHistory();

    const applyToRegistryRoute = RouteService.expand(RouteService.ANIMAL_REGISTRY_APPLICATION_SELECT, {panonId: animal.panonIdentifier.id});
    return <Widget displayName="Registrierungen"
                   cardMenuEntries={[
                       {
                           actionName: "Registrierung beantragen", actionFunction: () => {
                               history.push(applyToRegistryRoute)
                           }
                       }
                   ]}>
        {animalRegistrations.length
            ? <AnimalRegistrationsList animalRegistrations={animalRegistrations}/>
            : <>Tier noch nicht registriert. <RouterLink to={{pathname:applyToRegistryRoute}}>Registrierung beantragen</RouterLink> </>
        }
    </Widget>;
};

export default RegistryWidget;
