import * as React from 'react';
import {PropsWithChildren, ReactElement, useCallback, useMemo} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box, IconButton} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {debounce} from "lodash";


interface SearchFieldProps<T> {
    findSuggestions: (searchTerm: string) => Promise<T[]>
    renderOption?: (options: T) => string
    optionUniqueKey?: (option: T) => string
    value: T | null
    onValueChange?: (newValue: T | null) => void
    showSearchIcon?: boolean
    label?: string
}

/**
 * The search field executes the findSuggestions function for the input given through the text field. The findSuggestions function is executed in a debounced fashion to avoid
 * spamming the backend with requests.
 * The entered search term is always the first suggestion in the list.
 * The onValueChange function is called whenever the user picks a value from the suggestions.
 */
const SearchField = <T,>(props: PropsWithChildren<SearchFieldProps<T>>): ReactElement | null => {
    const {renderOption, findSuggestions, optionUniqueKey, onValueChange} = props;

    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly T[]>([]);

    const fetch = useCallback(async (input: string) => {
        const suggestions = await findSuggestions(input);
        setOptions(suggestions);
    }, [findSuggestions]);

    //debouncing that function to not flood the server when we do "search as you type"
    const fetchDebounced = useMemo(() => debounce(fetch, 300), [fetch]);

    return (
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Autocomplete
                sx={{flex: 1, margin: 1, marginLeft: 0, marginRight: 0}}
                getOptionLabel={renderOption && ((option) => typeof option === 'string' ? option : renderOption(option))}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                filterSelectedOptions
                value={props.value}
                onChange={(event: React.SyntheticEvent, newValue: string | T | null, reason) => {
                    if (typeof newValue != "string" && newValue !== null) {
                        onValueChange?.(newValue);
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    fetchDebounced(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} label={props.label ? props.label : "Search"} fullWidth/>
                )}
                renderOption={renderOption
                    && ((p, option) => <li {...p} key={optionUniqueKey?.(option) ?? String(option)}>{renderOption(option)}</li>)}
                freeSolo
            />
            {props.showSearchIcon && <IconButton sx={{p: "10px"}} aria-label="search" onClick={() => fetch(inputValue)}>
                <SearchIcon/>
            </IconButton>
            }

        </Box>
    );
}

export default SearchField;
