import Keycloak from "keycloak-js";
import {BreederSignupApi, SignUpBreeder} from "../api/generated/breeder-controller";
import ApiConfig from "../api/config/api-config";

const _kc: Keycloak = new Keycloak(process.env.KEYCLOAK_CONFIG_PATH ? process.env.KEYCLOAK_CONFIG_PATH : "/keycloak.json");
const signupBreederApi = new BreederSignupApi(new ApiConfig());

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = async (onAuthenticatedCallback: any) => {
    try {
        const authenticated = await _kc.init({
            onLoad: 'check-sso',
            pkceMethod: 'S256',
            checkLoginIframe: false,
        });

        if (!authenticated) {
            console.log("user is not authenticated..!");
        }

        try {
            console.log(await _kc.loadUserProfile());
        } catch (e) {
            console.error("error happened while loading user profile", e);
        } finally {
            onAuthenticatedCallback();
        }
    } catch (e) {
        console.error(e);
    }
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => {
    return !!_kc.token
};

const updateToken = (successCallback: any) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = (): string => {
    return _kc.authenticated && _kc.profile?.username ? _kc.profile.username : ""
};

const getAccountUrl = (): string => {
    return _kc.createAccountUrl();
}

const hasRole = (roles: string[]) => roles.some((role: string) => _kc.hasRealmRole(role));

const signUp = async (signUpRequest: SignUpBreeder): Promise<string> => {
    return (await signupBreederApi.signup(signUpRequest)).info
}

const AuthService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    signUp,
    getAccountUrl
};

export default AuthService;