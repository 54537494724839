import PropTypes from 'prop-types'
import {Route, RouteProps} from "react-router-dom";
import AuthService from "../../services/auth-service";
import React, {FunctionComponent} from "react";
import NotAllowed from "./not-allowed";

interface RolesRouteProperties extends RouteProps {
    roles: string[];
    path: string;
}

const RolesRoute:FunctionComponent<RolesRouteProperties> = ({ roles, children, ...rest }) => (
    <Route {...rest}>
        {AuthService.hasRole(roles) ? children : <NotAllowed/>}
    </Route>
)

RolesRoute.propTypes = {
    roles: PropTypes.array.isRequired,
    path: PropTypes.string.isRequired,
}

export default RolesRoute