import {PedigreeDto, PedigreeNodeDto} from "../api/dtos/pedigree-dto";
import {JsonPedigree, JsonPedigreeRecord, PedigreeApi} from "../api/generated/pedigree-rest";
import ApiConfig from "../api/config/api-config";
import {Result} from "../api/generated/lib-rest";
import {JsonAnimal} from "../api/generated/rest-dto";
import {AnimalService, IAnimalService} from "./animal-service";
import {IRegistrationService, RegistrationService} from "./registration-service";
import {IRegistryService, RegistryService} from "./registry-service";
import {RegistryDto} from "../api/generated/registry-service";
import axios, {AxiosError} from "axios";

interface IPedigreeService {
    loadPedigreeRecord(panonId: string): Promise<JsonPedigreeRecord>;

    loadPedigree(animal: JsonAnimal): Promise<PedigreeDto | null>;

    createPedigreeRecord(panonId: string): Promise<boolean>;

    setPedigreeRecord(pedigreeRecord: JsonPedigreeRecord): Promise<Result>;

    upsertPedigreeRecord(pedigreeRecord: JsonPedigreeRecord): Promise<Result>;
}

class PedigreeServiceImpl implements IPedigreeService {
    private _pedigreeApi: PedigreeApi;
    private _animalService: IAnimalService;
    private _registrationService: IRegistrationService
    private _registryService: IRegistryService;

    constructor(pedigreeApi: PedigreeApi, animalService: IAnimalService, registrationService: IRegistrationService, registryService: IRegistryService) {
        this._pedigreeApi = pedigreeApi;
        this._animalService = animalService;
        this._registrationService = registrationService;
        this._registryService = registryService;
    }

    async loadPedigreeRecord(panonId: string): Promise<JsonPedigreeRecord> {
        return (await this._pedigreeApi.getById(panonId)).item;
    }


    async loadPedigree(animal: JsonAnimal): Promise<PedigreeDto | null> {
        try {
        const loadedPedigree = (await this._pedigreeApi.getFullPedigreeById(animal.panonIdentifier.id)).item;
        const animals = await this._animalService.loadJsonAnimalsByPanonIds(this.mapToArrayOfPanonIds(loadedPedigree));
        const animalsMap = this.createAnimalsMap(animals);
        const animalRegistriesMap = await this.loadAnimalRegistriesMap(Array.from(animalsMap.keys()));
        const pedigreeRoot: PedigreeNodeDto | null = this.mapToPedigreeNodeDto(loadedPedigree, animalsMap, animalRegistriesMap);
        return pedigreeRoot ? {root: pedigreeRoot} : null;
        } catch(e) {
            if(axios.isAxiosError(e)){
                const ae = e as AxiosError
                if (ae.response?.status === 404) {
                    return null;
                }
            } else {
                throw e;
            }
        }
        return null;
    }

    private createAnimalsMap(animals: JsonAnimal[]): Map<string, JsonAnimal> {
        const animalsMap: Map<string, JsonAnimal> = new Map<string, JsonAnimal>();
        animals.forEach(a => {
            animalsMap.set(a.panonIdentifier.id, a)
        });
        return animalsMap;
    }


    private async loadAnimalRegistriesMap(panonIds: string[]) {
        const registrations = await this._registrationService.findRegistrationsByPanonIds(panonIds);
        const registryIds: string[] = [];
        const animalRegistriesMap: Map<string, RegistryDto[]> = new Map<string, RegistryDto[]>();
        registrations.forEach(regs => Array.from(regs).forEach(reg => registryIds.push(reg.registryId)))
        const registryDtos: Map<string, RegistryDto> = new Map<string, RegistryDto>();
        (await this._registryService.loadByIds(registryIds)).forEach(r => registryDtos.set(r.id, r));
        registrations.forEach((regs, panonId) =>
            animalRegistriesMap.set(panonId, regs
                .map(r => registryDtos.get(r.registryId))
                //as RegistryDto[] needed because typescript doesn't understand that I just removed undefined values via filter
                .filter(r => r !== undefined) as RegistryDto[])
        );
        return animalRegistriesMap;
    }

    /**
     * Creates a pedigree record if it does not exist yet.
     * @param panonId The panonId of the animal to create the record for (the offspring)
     * @returns true if a pedigree record was created. Returns false otherwise.
     */
    async createPedigreeRecord(panonId: string): Promise<boolean> {
        try {
            const existingRecord = await this._pedigreeApi.getById(panonId);
            //If statement just in case the api will not throw an error in the future but return an "error" Result object.
            if (existingRecord && existingRecord.item && existingRecord.item.panonId) {
                return false;
            }
            return false;
        } catch {
            return (await this._pedigreeApi.addToPedigree(panonId)).info === "success";
        }
    }

    async setPedigreeRecord(pedigreeRecord: JsonPedigreeRecord): Promise<Result> {
        return this._pedigreeApi.setPedigreeRecord(pedigreeRecord);
    }

    async upsertPedigreeRecord(pedigreeRecord: JsonPedigreeRecord): Promise<Result> {
        await this.createPedigreeRecord(pedigreeRecord.panonId);
        return this.setPedigreeRecord(pedigreeRecord);
    }

    private mapToArrayOfPanonIds(pedigree: JsonPedigree): string[] {
        if (!pedigree) return [];
        return [
            ...this.mapToArrayOfPanonIds(pedigree.dam),
            ...this.mapToArrayOfPanonIds(pedigree.sire),
            pedigree.panonId
        ];
    }

    private mapToPedigreeNodeDto(pedigree: JsonPedigree, animalsMap: Map<string, JsonAnimal>, animalRegistriesMap: Map<string, RegistryDto[]>): PedigreeNodeDto | null {
        const animal: JsonAnimal | undefined = animalsMap.get(pedigree.panonId);
        const registries = animalRegistriesMap.get(pedigree.panonId);
        return animal ? {
            sire: pedigree.sire ? this.mapToPedigreeNodeDto(pedigree.sire, animalsMap, animalRegistriesMap) : null,
            dam: pedigree.dam ? this.mapToPedigreeNodeDto(pedigree.dam, animalsMap, animalRegistriesMap) : null,
            fullName: this._animalService.getAnimalFullName(animal),
            panonId: pedigree.panonId,
            registries: registries ? registries.map(regDto => regDto.name) : [],
            sex: animal.sex
        } : null
    }
}

const pedigreeApi = new PedigreeApi(new ApiConfig());
export const PedigreeService = new PedigreeServiceImpl(pedigreeApi, AnimalService, RegistrationService, RegistryService);