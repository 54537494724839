// @flow
import React, {FunctionComponent} from 'react';
import {useMutation, useQuery} from "react-query";
import {BreederService} from "../services/breeder-service";
import {FarmData} from "../api/dtos/breeder-update";
import BreederDetailsForm from "../components/Breeder/breeder-details-form";


const Farm: FunctionComponent = () => {


    const breederUpdateMutation = useMutation({
        mutationKey: "breederUpdate",
        mutationFn: (farmData: FarmData) => BreederService.updateBreeder(farmData)
    })

    const {data, dataUpdatedAt} = useQuery({
        queryKey: ["ownDetails"],
        queryFn: async () => {
            const details = await BreederService.ownDetails();
            const farmData: FarmData = {
                ...details,
                owners: details.owners.map(o => ({name: o}))
            }
            console.log("Farm Data")
            console.log(farmData)
            return farmData;
        }
    })

    //The key forces a reset of the form in case the updated data timestamp changed
    return (!data ? null : <BreederDetailsForm key={`own-details-form-${dataUpdatedAt}`} onSubmit={breederUpdateMutation.mutate} initialData={data} />)
};

export default Farm