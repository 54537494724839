export interface IFeatureFlagService {
    isEnabled(featureFlag: string): boolean
}

class FeatureFlagServiceImpl implements IFeatureFlagService {

    private _featureFlags: {[key:string]: boolean} = {
        EVENT_LIST: false,
        ADMIN_AREA: true
    }
    isEnabled(featureFlag: string): boolean {
        return this._featureFlags[featureFlag];
    }


}

export const FeatureFlagService: IFeatureFlagService = new FeatureFlagServiceImpl();
