// @flow
import React, {FunctionComponent} from 'react';
import FlexCard from "../../AnimalReview/flex-card";
import Pedigree from "../../Pedigree/pedigree";
import {JsonAnimal} from "../../../api/generated/rest-dto";

interface PedigreeCardProps {
    animal: JsonAnimal
}

const PedigreeCard: FunctionComponent<PedigreeCardProps> = ({animal}) => {
    return (
        <FlexCard title="Stammbaum">
            <Pedigree animal={animal} />
        </FlexCard>
    );
};

export default PedigreeCard