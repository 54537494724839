import {ApiConfigPort} from '../config/api-config-port';
import {HttpService} from '../../services/http-service';
import {CreateResult, ListResult, UpdateResult} from './lib-rest';

export interface CreateHelpArticle {
  fullUrl: string;
  summary: string;
  title: string;
  videoUrl: string;
}

export class HelpApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async createHelpArticle(createHelpArticle: CreateHelpArticle): Promise<CreateResult>  {
const url =  new URL('/help-articles', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(createHelpArticle))).data
  }

 public  async deleteHelpArticle(id: string): Promise<Response>  {
const url =  new URL('/help-articles/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async getAllHelpArticles(searchTerm?: string): Promise<ListResult<HelpArticle> >  {
const url =  new URL('/help-articles', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    if (searchTerm !== undefined && searchTerm !== null) {
      queryParamsList.push({name: 'search', value: searchTerm});
    }

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getHelpArticleById(id: string): Promise<HelpArticle>  {
const url =  new URL('/help-articles/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async updateHelpArticle(id: string, helpArticle: HelpArticle): Promise<UpdateResult>  {
const url =  new URL('/help-articles/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString(),JSON.stringify(helpArticle))).data
  }

}

export interface HelpArticle {
  fullUrl: string;
  id: string;
  summary: string;
  title: string;
  videoUrl: string;
}

