import {DateTime} from "luxon";

/**
 * Transforms iso8601 strings in properties that contain "date" or "timestamp" in their name into Luxon DateTime objects recursively.
 *
 * - It will NOT fail if a property with matching name pattern contains something different than an iso8601 string. Instead, it
 * will leave the original value assigned to the property.
 *
 * - It will NOT convert values in 2d arrays since the 2nd dimension of a 2d array does not have a property name.
 *
 * @param object The object to transform iso8601 strings into Luxon DateTimes depending on property name.
 */
const convertObjectPropertiesFromStringToDate = (object: any): any => {
    if(object === null) return null;
    if(object === undefined) return undefined;
    for (const key of Object.keys(object)) {
        let obj = object[key];
        const dateProp = isDateProperty(key);
        if (Array.isArray(obj)) { // loop array and either do recursion or transformation
            for (let i: number = 0; i < obj.length; i++) {
                const cur = obj[i];
                if (dateProp && typeof cur === "string") {
                    obj[i] = transformToDateFromString(cur);
                } else {
                    obj[i] = convertObjectPropertiesFromStringToDate(cur);
                }
            }
        } else if (typeof object[key] == "object") {
            object[key] = convertObjectPropertiesFromStringToDate(obj);
        } else if (dateProp) {
            object[key] = transformToDateFromString(obj);
        }
    }
    return object;
}

const transformToDateFromString = (stringValue: string): DateTime | string => {
    let dateTime = DateTime.fromISO(stringValue);
    if (!dateTime.isValid) {
        dateTime = DateTime.fromSQL(stringValue);
    }
    return dateTime.isValid ? dateTime : stringValue;
}

const transformToISODateFromDateTime = (dateTime: any): string => {
    return DateTime.isDateTime(dateTime) ? (dateTime as DateTime).toISODate() : dateTime.toString();
}

const isDateProperty = (propertyName: string): boolean => propertyName.toLowerCase().includes("date") || propertyName.toLowerCase().includes("timestamp")


const DateConverter =  {
    convertObjectPropertiesFromStringToDate,
    transformToISODateFromDateTime
}
export default DateConverter;