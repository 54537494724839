// @flow
import React, {FunctionComponent} from 'react';
import {LinearProgress, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import RouteService from "../../../services/route-service";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {PregnancyListJson} from "../../../api/generated/medical-rest";
import {ParentType, ParentTypeValues} from "../../../api/generated/herds-pedigree";
import {ErrorBox} from "../../Common/error-box";
import {usePartners} from './hooks/use-partners';

type Props = {
    pregnancies: PregnancyListJson[],
    baseParentKind: ParentType,
};

type PregnancyListItemProps = {
    pregnancy: PregnancyListJson,
    partner: JsonAnimal,
}


const getPregnancyState = (recordedMating: boolean, confirmedPregnancy: boolean, completedPregnancy: boolean) => {
    if (recordedMating) {
        if (confirmedPregnancy) {
            return completedPregnancy ? ' (abgeschlossen)' : ' (trächtig)';
        }
        if (completedPregnancy) {
            return ' (erfolglos)'
        }
        return ' (gedeckt)'
    }
    if (confirmedPregnancy) {
        return completedPregnancy ? ' (abgeschlossen)' : ' (trächtig)';
    }
    return '';
};

const MatingListItem: FunctionComponent<PregnancyListItemProps> = ({partner, pregnancy}) => {
    const partnerName = partner ? `${partner.herdCode} ${partner.name}` : '';

    const recordedMating = !!pregnancy.matingDate;
    const confirmedPregnancy = !!pregnancy.startDate;
    const completedPregnancy = !!pregnancy.actualEndDate;

    const date = pregnancy.matingDate ?? pregnancy.startDate;
    const pregnancyState = getPregnancyState(recordedMating, confirmedPregnancy, completedPregnancy);
    return (
        <ListItem>
            <ListItemButton component={RouterLink}
                            to={{pathname: RouteService.expand(RouteService.PREGNANCY_DETAILS, {pregnancyId: pregnancy.id})}}>
                <ListItemText primary={`${partnerName}${pregnancyState}`} secondary={date.toLocaleString()}/>
            </ListItemButton>
        </ListItem>
    );

}

const MatingList: React.FC<Props> = ({pregnancies, baseParentKind}) => {
    const {partners, isLoading, isError} = usePartners(pregnancies, baseParentKind);

    if (isLoading) {
        return <LinearProgress/>;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Deckungen.</ErrorBox>;
    }

    if (!partners) {
        return <ErrorBox>Deckungen können nicht angezeigt werden.</ErrorBox>;
    }

    return (
        <List>
            {pregnancies.map((p) => {
                const partner = partners.get(baseParentKind === ParentTypeValues.DAM ? (p.fatherAnimalId ?? '') : p.motherAnimalId);
                if (!partner) {
                    return null;
                }
                return <MatingListItem key={p.id} partner={partner} pregnancy={p}/>;
            }).filter(Boolean)}
        </List>
    );
};

export default MatingList;
