import React, {FC} from 'react';
import {SxProps, Typography} from '@mui/material';
import {Variant} from "@mui/material/styles/createTypography";
import {DateTime} from "luxon";

interface FormattedDateProps {
 sx: SxProps,
 variant?: Variant
 date: DateTime
}

 const FormattedDate: FC<FormattedDateProps> = ({sx, variant, date}) => (
  <Typography
    sx={sx}
    component="span"
    variant={variant}
  >
    {date ? date.toLocaleString(): "n.a."}
  </Typography>
);

export default FormattedDate;