import {useQuery} from "react-query";
import {RegistrationService} from "../../../services/registration-service";

export const useRegistrations = (panonId: string | undefined, suspense: boolean = false) => {
    const {data, isLoading, isError, refetch} = useQuery({
        queryKey: ['registrations', panonId],
        queryFn: () => RegistrationService.findRegistrationsByPanonId(panonId!),
        enabled: !!panonId,
        suspense,
    });

    return {
        registrations: data,
        isLoading,
        isError,
        refetch
    };
};
