import React, {ChangeEvent, useCallback, useState} from "react";
import {SelectChangeEvent} from "@mui/material";

export type FormFieldValueTransformer<T> = (value: any) => T;

const useFormFields = <T>(initialValues: T) => {
    const [formFields, setFormFields] = useState(initialValues);
    const createChangeHandler = useCallback(<K extends keyof T>(key: K, valueTransformer?: FormFieldValueTransformer<T[K]>) => (e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent | any) => {
        const rawValue = e?.target?.value ?? e;
        const value = valueTransformer ? valueTransformer(rawValue) : rawValue;
        setFormFields((prev: T) => ({...prev, [key]: value}));
    }, [setFormFields]);
    return {formFields, createChangeHandler};
}

export default useFormFields;