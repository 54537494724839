// @flow
import React, {Fragment, FunctionComponent} from 'react';
import {Divider, List} from "@mui/material";
import {JsonAnimal} from "../../api/generated/rest-dto";
import AnimalListItem from "./animal-list-item";
import {PregnancyListJson} from "../../api/generated/medical-rest";


type JsonAnimalWithPregnancy = JsonAnimal & { pregnancy?: PregnancyListJson; };

interface AnimalListProps {
    items: (JsonAnimalWithPregnancy | undefined)[],
    onItemClick?: (animal: JsonAnimal) => void,
    showTags?: boolean,
    onTagClick?: (tag:string) => void
    showDivider?: boolean
}

const AnimalList: FunctionComponent<AnimalListProps> = ({items, onItemClick, showTags, onTagClick, showDivider = true}) => {
    return (
        <List>
            {items.filter((a): a is JsonAnimalWithPregnancy => !!a).map((animalData: JsonAnimalWithPregnancy) => (
                <Fragment key={animalData.id}>
                    <AnimalListItem animal={animalData} onItemClick={onItemClick} showTags={showTags} onTagClick={onTagClick} />
                    {showDivider && <Divider variant="inset" component="li"/>}
                </Fragment>
            ))}
        </List>
    );
};

export default AnimalList