import {create} from 'zustand'
import { SeverityType } from './severity-type'


interface GlobalSnackbarStoreState {
    expandedRoute?: string | "history-back",
    message: string,
    severity: SeverityType,
    success: (message: string, expandedRoute?: string) => void,
    info: (message: string, expandedRoute?: string) => void,
    warning: (message: string, expandedRoute?: string) => void,
    error: (message: string, expandedRoute?: string) => void,
    hide: () => void,
    show: boolean
}

export const useGlobalSnackbarStore = create<GlobalSnackbarStoreState>()(
    (set) => ({
            message: "",
            severity: "info",
            expandedRoute: undefined,
            show: false,
            hide: () => set((state) => ({...state, show: false})),
            success: (message: string, expandedRoute?: string) => set((state) => ({severity: "success", message, expandedRoute, show: true})),
            info: (message: string, expandedRoute?: string) => set((state) => ({severity: "info", message, expandedRoute, show: true})),
            warning: (message: string, expandedRoute?: string) => set((state) => ({severity: "warning", message, expandedRoute, show: true})),
            error: (message: string, expandedRoute?: string) => set((state) => ({severity: "error", message, expandedRoute, show: true})),
        }
    )
)