enum TreatmentType {
    weighing = "treatmentType.weighing",
    other = "treatmentType.other",
    vaccination = "treatmentType.vaccination",
    vitaminsAndMinerals = "treatmentType.vitaminsandminerals",
    antiparasitics = "treatmentType.antiparasitics",
    otherMedication = "treatmentType.othermedication",
    bodyscore = "treatmentType.bodyscore",
    spittest = "treatmentType.spittest",
    ultrasound = "treatmentType.ultrasound"
}

export default TreatmentType;