import React from 'react';
import {Link as RouterLink, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import Widget from "../../Common/widget";
import RouteService from "../../../services/route-service";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import ChipsList from "../../chips-list";
import {AnimalRecordStatusValues} from "../../../api/generated/herd-animal";
import EditIcon from "@mui/icons-material/Edit";

interface TagsProperties extends RouteComponentProps {
    animal: JsonAnimal;
}

const Tags: React.FC<TagsProperties> = (props: TagsProperties) => {
    const {animal} = props;
    const history = useHistory();

    const handleTagClick = (tag: string) => {
        history.push(RouteService.expand(RouteService.HERD_GROUP, {groupId: tag, recordStatus: AnimalRecordStatusValues.HERD}));
    };

    const tags = animal.tags;

    const editTagRoute = RouteService.expand(RouteService.TAGS_EDIT, {panonId: animal.panonIdentifier.id});
    const editTagAction = () => history.push(editTagRoute);
    return (
        <Widget className="tags-widget" displayName="Tags"
                cardMenuEntries={[{
                    actionName: "Tags bearbeiten",
                    actionFunction: editTagAction
                }]}
                quickActions={[
                    { icon: <EditIcon />, action: editTagAction }
                ]}
            >
            {tags.length
                ? <ChipsList listItems={tags} onItemClick={handleTagClick}/>
                : <>Noch keine Tags. <RouterLink to={{pathname: editTagRoute}}>Tags hinzufügen</RouterLink></>}
        </Widget>
    );
};

export default withRouter(Tags);