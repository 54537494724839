import * as React from 'react';
import {Fragment, FunctionComponent} from 'react';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
} from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import TreatmentFormatter, {
    FormattingFunction,
    TreatmentFormatterConfiguration,
    TypeFormatters
} from "./treatment-formatter";
import DeleteIcon from '@mui/icons-material/Delete';
import TreatmentType from "../../../api/dtos/treatment-type";
import {DateTime} from "luxon";
import {TreatmentListJson} from "../../../api/generated/medical-rest";
import {
    BalanceOutlined,
    BloodtypeOutlined,
    Filter3Outlined,
    HealingOutlined,
    HealthAndSafetyOutlined,
    MedicationOutlined,
    PestControlOutlined,
    SpatialAudioOutlined,
    VaccinesOutlined
} from "@mui/icons-material";

class PrimaryTextFormatterConfig implements TreatmentFormatterConfiguration {
    defaultFormatting: FormattingFunction;
    typeFormatting: TypeFormatters;

    constructor() {
        this.defaultFormatting = (treatment) => `${treatment.type}`;
        this.typeFormatting = {
            [TreatmentType.weighing as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Wiegung", "value"),
            [TreatmentType.otherMedication as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Sonstige Medikation", "dose"),
            [TreatmentType.other as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Sonstige Behandlung", null),
            [TreatmentType.spittest as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Spucktest", null),
            [TreatmentType.ultrasound as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Ultraschall", null),
            [TreatmentType.vaccination as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Impfung", "dose"),
            [TreatmentType.bodyscore as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Bodyscore", "value"),
            [TreatmentType.vitaminsAndMinerals as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Vitamine und Mineralstoffe", "dose"),
            [TreatmentType.antiparasitics as string]: PrimaryTextFormatterConfig.generateFormatterFunction("Parasitenbehandlung", "dose"),
        }
    }

    static generateFormatterFunction(treatmentTypeText: string, valueField: "value" | "dose" | null): (treatment: TreatmentListJson) => string {
        return treatment => `${treatmentTypeText} ${valueField ? "| " + treatment[valueField] + " " + treatment.unit + " " + treatment.drug : ""}`
    }
}

const secondaryTextConfig: TreatmentFormatterConfiguration = {
    defaultFormatting: treatment => `${treatment.timestamp.toLocaleString(DateTime.DATE_SHORT)} | ${treatment.note}`
};

const primaryTextFormatter: TreatmentFormatter = new TreatmentFormatter(new PrimaryTextFormatterConfig());
const secondaryTextFormatter: TreatmentFormatter = new TreatmentFormatter(secondaryTextConfig);

type TreatmentListItemProps = {
    treatment: TreatmentListJson;
    onClick?: (treatment: TreatmentListJson) => void;
    onTreatmentDelete?: (treatmentId: string) => void;
    editable?: boolean;
};

type TreatmentListItemContentProps = {
    treatment: TreatmentListJson
}

const TreatmentTypeDependentIcon: FunctionComponent<{treatmentType: string}> = ({treatmentType}) => {
    switch (treatmentType) {
        case TreatmentType.weighing: return <BalanceOutlined/>
        case TreatmentType.other: return <HealingOutlined />
        case TreatmentType.vaccination: return <VaccinesOutlined />
        case TreatmentType.vitaminsAndMinerals: return <HealthAndSafetyOutlined/>
        case TreatmentType.antiparasitics: return <PestControlOutlined/>
        case TreatmentType.otherMedication: return <MedicationOutlined />
        case TreatmentType.bodyscore: return <Filter3Outlined />
        case TreatmentType.spittest: return <BloodtypeOutlined />
        case TreatmentType.ultrasound: return <SpatialAudioOutlined />
        default: return <FolderIcon />
    }
}


const TreatmentListItemContent: FunctionComponent<TreatmentListItemContentProps> = ({treatment}) => {
    return (
        <Fragment>
            <ListItemAvatar>
                <Avatar>
                    <TreatmentTypeDependentIcon treatmentType={treatment.type}/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={primaryTextFormatter.format(treatment)}
                secondary={secondaryTextFormatter.format(treatment)}
            />
        </Fragment>
    )
}


export const TreatmentListItem: FunctionComponent<TreatmentListItemProps> = ({treatment, onClick, onTreatmentDelete, editable = true}) => {
    return editable ? (
            <ListItemButton key={treatment.id} alignItems="flex-start" onClick={() => {
                if (onClick) {
                    onClick(treatment)
                }
            }}>
                <TreatmentListItemContent treatment={treatment}/>
                <ListItemSecondaryAction>
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(event) => {
                            if (onTreatmentDelete) {
                                onTreatmentDelete(treatment.id)
                                event.stopPropagation(); //avoid triggering the onClick of the ListItemButton component (=parent).
                            }
                        }}
                        size="large">
                        <DeleteIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
        ) :
        (<ListItem>
            <TreatmentListItemContent treatment={treatment}/>
        </ListItem>)
};