// @flow
import React, {FunctionComponent} from 'react';
import {Menu, MenuItem} from "@mui/material";

export interface ActionMenuEntry {
    actionName: string,
    actionFunction: () => void
}

type Props = {
    actionMenuEntries?: ActionMenuEntry[];
    anchor: HTMLElement | null;
    onClose: () => void;
};

const ActionsMenu: FunctionComponent<Props> = ({actionMenuEntries = [], anchor, onClose}) => {
    const handleActionsMenuClose = (fkt: (() => void) | null) => {
        onClose();
        fkt?.();
    };
    if (actionMenuEntries.length === 0) {
        return null;
    }
    return (
        <Menu anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={() => handleActionsMenuClose(null)}>
            {actionMenuEntries.map((action) => (
                <MenuItem key={action.actionName} onClick={() => handleActionsMenuClose(action.actionFunction)}>
                    {action.actionName}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default ActionsMenu