import {ApiConfigPort} from '../config/api-config-port';
import {AlpacaFiberColor, PanonIdentifier, Sex} from './herd-animal';
import {HttpService} from '../../services/http-service';
import {CreateResult, ListResult, Result, SingleResult, UpdateResult} from './lib-rest';
import {CreateAnimalDto, JsonAnimal, JsonUpdateAnimal, JsonUpdateIdentifier, TagAnimalDto} from './rest-dto';

export class AnimalApi {
  config: ApiConfigPort;


 public  async activate(panonId: string): Promise<UpdateResult>  {
const url =  new URL('/animals/' + panonId + '/activate', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString())).data
  }

 public  async colors(): Promise<ListResult<AlpacaFiberColor> >  {
const url =  new URL('/animals/colors', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async create(dto: CreateAnimalDto): Promise<any>  {
const url =  new URL('/animals', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(dto))).data
  }

 public  async deactivate(panonId: string): Promise<UpdateResult>  {
const url =  new URL('/animals/' + panonId + '/deactivate', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString())).data
  }

 public  async get(id: string): Promise<any>  {
const url =  new URL('/animals/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getAll(): Promise<ListResult<JsonAnimal> >  {
const url =  new URL('/animals', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getBulkByPanonIds(panonIds: string[]): Promise<ListResult<JsonAnimal> >  {
const url =  new URL('/animals/panonId/_by/ids', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(panonIds))).data
  }

 public  async getByPanonId(panonId: string): Promise<SingleResult<JsonAnimal> >  {
const url =  new URL('/animals/panonId/' + panonId + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getByTags(tagNames: string[]): Promise<ListResult<JsonAnimal> >  {
const url =  new URL('/animals/tagged/' + tagNames + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async makeHerdMember(animalId: string): Promise<any>  {
const url =  new URL('/animals/' + animalId + '/makeHerdAnimal', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString())).data
  }

 public  async sexes(): Promise<ListResult<Sex> >  {
const url =  new URL('/animals/sexes', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async update(animal: JsonUpdateAnimal): Promise<UpdateResult>  {
const url =  new URL('/animals', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString(),JSON.stringify(animal))).data
  }

 public  async updateIdentifier(panonId: string, updateIdentifier: JsonUpdateIdentifier): Promise<UpdateResult>  {
const url =  new URL('/animals/' + panonId + '/ids', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(updateIdentifier))).data
  }

}

export interface AnimalController$CreateAnimalResult extends  CreateResult{
  panonIdentifier: PanonIdentifier;
}

export class TagApi {
  config: ApiConfigPort;


 public  async add(dto: TagAnimalDto): Promise<Result>  {
const url =  new URL('/animals/tags', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString(),JSON.stringify(dto))).data
  }

 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async herdTags(): Promise<ListResult<string> >  {
const url =  new URL('/animals/tags', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async set(dto: TagAnimalDto): Promise<Result>  {
const url =  new URL('/animals/tags', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(dto))).data
  }

}

