import React, {useCallback, useState} from 'react';
import {Alert, Avatar, LinearProgress, ListItem, ListItemAvatar, Skeleton, Stack, Tab, Tabs} from '@mui/material';
import {RouteComponentProps, withRouter} from "react-router-dom";
import RouteService from "../services/route-service";
import FlexStack from "../components/Common/flex-stack";
import {SearchField, TabPanel} from "../components/Common";
import {useTranslation} from "react-i18next";
import {ErrorBox} from "../components/Common/error-box";
import List from "@mui/material/List";
import {useTabs} from "../components/Common/hooks/use-tabs";
import {BreederSearchResult} from "../api/generated/herd-breeder";
import {BreederService} from "../services/breeder-service";
import {useQuery} from "react-query";
import BreederList from "../components/Common/breeder-list";

const useBreeders = (searchTerm: string) => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['breeders', searchTerm],
        queryFn: async () => BreederService.search(searchTerm),
        enabled: !!searchTerm,
    });

    return {
        breeders: data,
        isLoading,
        isError,
    };
};

const TabbedBreeders = ({breeders, onBreederClick}: {
    breeders: BreederSearchResult[],
    onBreederClick: (breeder: BreederSearchResult) => void;
}) => {
    const [selectedTab, setSelectedTab] = useTabs();
    return (
        <>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <Tab label={`Alle (${breeders.length})`}/>
            </Tabs>

            <TabPanel value={selectedTab} index={0}>
                <BreederList breeders={breeders} onListItemClick={onBreederClick} />
            </TabPanel>
        </>
    );
};

const LoadingItem = () => (
    <ListItem>
        <ListItemAvatar><Avatar/></ListItemAvatar>
        <Skeleton width="100%"/>
    </ListItem>
);

const FilteredBreeders = ({searchTerm, onBreederClick}: {
    searchTerm: string;
    onBreederClick: (breeder: BreederSearchResult) => void;
}) => {
    const {breeders = [], isLoading, isError} = useBreeders(searchTerm);

    if (isLoading) {
        return (
            <Stack>
                <LinearProgress/>

                <Tabs value={0}>
                    <Tab label={`Alle (...)`}/>
                </Tabs>

                <TabPanel value={0} index={0}>
                    <List>
                        <LoadingItem/>
                        <LoadingItem/>
                        <LoadingItem/>
                    </List>
                </TabPanel>
            </Stack>
        );
    }

    if (!searchTerm) {
        return <Alert severity="info">Suchbegriff eingeben, um die Suche zu starten</Alert>;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Züchter</ErrorBox>;
    }

    if (!breeders.length) {
        return (
            <Alert severity="info">
                Keine Züchter gefunden.
            </Alert>
        );
    }

    return <TabbedBreeders breeders={breeders} onBreederClick={onBreederClick}/>;
};

const Breeders: React.FC<RouteComponentProps> = ({history}) => {
    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('')

    const performSearch = useCallback(async (searchTerm: string): Promise<BreederSearchResult[]> => {
        setSearchTerm(searchTerm);
        return []; //we don't show suggestions on the searchfield here, so [] is returned
    }, []);

    const handleBreederClick = (breeder: BreederSearchResult) => {
        history.push({pathname: RouteService.expand(RouteService.BREEDER_DETAILS, {breederId: breeder.id})});
    };

    return (
        <FlexStack>
            <SearchField<BreederSearchResult> label={t("breeders.filter")}
                                              findSuggestions={performSearch}
                                              value={null}
            />
            <FilteredBreeders onBreederClick={handleBreederClick} searchTerm={searchTerm}/>
        </FlexStack>
    );
};

export default withRouter(Breeders);
