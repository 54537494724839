// @flow
import React, {FunctionComponent, useState} from 'react';
import {CreateAnimalDto} from "../api/generated/rest-dto";
import {AnimalService} from "../services/animal-service";
import {useLocation} from "react-router-dom";
import OnCloseNavigationSnackbar from "../components/Common/on-close-navigation-snackbar";
import RouteService from "../services/route-service";
import AnimalBaseDataForm from "../components/AnimalCreate/animal-base-data-form";
import {PregnancyListJson} from "../api/generated/medical-rest";
import {AnimalBaseData, transformToCreateAnimalDto} from "../api/dtos/animal-base-data";
import {AnimalController$CreateAnimalResult} from "../api/generated/animal-controller";


export interface AnimalCreateLocationState {
    createForHerd: boolean,
    pregnancy?: PregnancyListJson
}

const AnimalCreate: FunctionComponent = () => {
    const {state: {createForHerd, pregnancy}} = useLocation<AnimalCreateLocationState>();

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [panonId, setPanonId] = useState<string>("");

    const onRegisterClicked = async (animal: AnimalBaseData) => {
        //validate form inputs
        const createAnimalDto: CreateAnimalDto = transformToCreateAnimalDto(animal, pregnancy?.id);
        const createdAnimal: AnimalController$CreateAnimalResult = createForHerd
            ? await AnimalService.createForHerd(createAnimalDto)
            : await AnimalService.createForPedigree(createAnimalDto);
        if (createdAnimal.info === "created") {
            setPanonId(createdAnimal.panonIdentifier.id);
            setSnackbarOpen(true);
        } else {
            //TODO display error! (or even better, do error handling in the service already?!
        }
    };

    return (
        <div>
            <AnimalBaseDataForm onSubmitClicked={onRegisterClicked} pregnancy={pregnancy} />
            {createForHerd ?
                <OnCloseNavigationSnackbar expandedRoute={RouteService.expand(RouteService.ANIMAL_CHANGE_ANCESTRY, {panonId})} message="Tier wurde angelegt!" triggerSnackbar={snackbarOpen}/> :
                <OnCloseNavigationSnackbar message="Tier wurde angelegt!" triggerSnackbar={snackbarOpen}/>
            }
        </div>
    );
};

export default AnimalCreate
