// @flow
import React, {Fragment, FunctionComponent} from 'react';
import {Box, Divider, ListItemAvatar, ListItemButton, ListItemText, Stack} from "@mui/material";
import RouteService from "../../services/route-service";
import {RegistrationRequestController$PendingRequestJson} from "../../api/generated/registry-rest";
import {AnimalAvatar} from "../Common/animal-avatar";
import {DateTime} from "luxon";
import {useNav} from "../Common/hooks/use-nav";

interface ReviewRequestListItemProps {
    application: RegistrationRequestController$PendingRequestJson
}

const ReviewRequestListItem: FunctionComponent<ReviewRequestListItemProps> = ({application}) => {

    const nav = useNav();
    const isRejected = !!application.rejectionTime;

    return (
        <Fragment>
            <ListItemButton onClick={() => nav.push(RouteService.REGISTRY_ANIMAL_REVIEW, {id: application.id})}>
                <ListItemAvatar>
                    <AnimalAvatar animal={application.animal}/>
                </ListItemAvatar>
                <ListItemText primary={`${application.animal.name}`} secondary={<Stack>
                    <Box>Züchter: {application.breeder.name}</Box>
                    <Box>Register: {application.registry.name}</Box>
                    {isRejected && <Box>Abgelehnt am: {DateTime.fromISO(application.rejectionTime).toLocaleString()}</Box>}
                    {isRejected && <Box>Kommentar: {application.rejectionComment}</Box>}
                </Stack>} />
            </ListItemButton>
            <Divider variant="middle" component="li"/>
        </Fragment>
    );

};

export default ReviewRequestListItem