import {DateTime} from "luxon";
import {RegistryDto} from "../generated/registry-service";
import {AlpacaBreed, AlpacaFiberColor, PanonIdentifier, Sex} from "../generated/herd-animal";
import {RegistrationsController$RegistrationJson} from "../generated/registry-rest";

export default class AnimalSearchResult {

    name:string;
    herdCode: string;
    sex: Sex;
    panonId: PanonIdentifier;
    fiberColor: AlpacaFiberColor;
    dateOfBirth: DateTime;
    breed: string | AlpacaBreed;

    breeder: string;
    owner: string;
    registries: RegistryDto[];


    static FromRegistration(registration: RegistrationsController$RegistrationJson, registry: RegistryDto | undefined): AnimalSearchResult {
        const registries = registry ? [registry] : [];
        return new AnimalSearchResult(
            {id: registration.panonIdentifier, animalIdentifiers: []},
            registration.name,
            registration.herdCode,
            registration.phenotype.dateOfBirth,
            registration.phenotype.breed,
            registration.phenotype.primaryColor,
            registration.phenotype.sex,
            "-",
            "-",
            registries)
    }

    constructor(panonId:PanonIdentifier, name: string, herdCode: string, birthDate: DateTime, breed: string | AlpacaBreed, fiberColor: AlpacaFiberColor, sex:Sex, breeder: string, owner: string, registries: RegistryDto[]) {
        this.panonId = panonId;
        this.name = name;
        this.herdCode = herdCode;
        this.dateOfBirth = birthDate;
        this.breed = breed;
        this.fiberColor = fiberColor;
        this.breeder = breeder;
        this.owner = owner;
        this.registries = registries;
        this.sex = sex;
    }


}