// @flow
import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {Tree, TreeNode} from "react-organizational-chart";
import {PedigreeNodeDto} from "../../api/dtos/pedigree-dto";
import {PedigreeService} from "../../services/pedigree-service";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    IconButton,
    LinearProgress,
    SxProps,
    Typography
} from "@mui/material";
import {grey} from "@mui/material/colors";
import {GroupAddOutlined, GroupRemoveOutlined} from "@mui/icons-material";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {SexValues} from "../../api/generated/herd-animal";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {ErrorBox} from "../Common/error-box";

interface PedigreeProps {
    animal: JsonAnimal,
    depth?: number
}

interface PedigreeNodeProps {
    node: PedigreeNodeDto | undefined | null
    childLevelsToShow: number
}

interface StyledNodeProps {
    showsAncestors: boolean
    onAncestorShowHideClicked: (show: boolean) => void
    hasAncestors?: boolean
    node: PedigreeNodeDto | undefined | null
    showImage? : boolean
}

const StyledNode: FunctionComponent<StyledNodeProps> = ({
                                                            node,
                                                            showsAncestors,
                                                            onAncestorShowHideClicked,
                                                            hasAncestors = true,
                                                            showImage = false
                                                        }) => {
    const styles = {
        node: {
            padding: '5px',
            borderRadius: '8px',
            display: 'inline-block',
            borderTop: '3px solid',
            maxWidth: 200,
            borderTopColor: `${node && node.sex === SexValues.MALE ? "male.dark" : "female.main"}`
        } as SxProps
    };

    const {t} = useTranslation();

    const toggleAncestorDisplayIfNodeIsSet = (show: boolean) => {
        if (node) onAncestorShowHideClicked(show);
    }

    const background = node?.registries && node.registries.length === 0 ? 'warning.light' : ''
    return <Card sx={{backgroundColor: background, ...styles.node}}>
        {showImage && <CardMedia
            component="img"
            height="140"
            image="/alpaca_placeholder.jpg"
            alt="Alpaka Profilbild"
        />}
        {node && <CardContent>
            <Typography gutterBottom variant="h6" component="div">
                {node.fullName}
            </Typography>
            {node.registries.length > 0 && <Fragment>
                <Typography variant="subtitle1">
                    {t("registered_at")}
                </Typography>
                <Typography>
                    {node.registries}
                </Typography>
            </Fragment>

        }
    </CardContent>
}
{
    hasAncestors && <CardActions>
        {showsAncestors ? <IconButton color="primary" aria-label="hide ancestors" component="label"
                                      onClick={() => toggleAncestorDisplayIfNodeIsSet(false)}>
            <GroupRemoveOutlined/>
        </IconButton> : <IconButton color="primary" aria-label="show ancestors" component="label"
                                    onClick={() => toggleAncestorDisplayIfNodeIsSet(true)}>
            <GroupAddOutlined/>
        </IconButton>}
    </CardActions>
}
</Card>
}

const PedigreeNode: FunctionComponent<PedigreeNodeProps> = ({node, childLevelsToShow}) => {
    // if/else cascade is needed here because if we render the inner Pedigree nodes as "null" in case dam and sire are not defined,
    // the react tree component would render connections emerging from the leafs leading to no other nodes (because no further nodes exist)
    const [showAncestors, setShowAncestors] = useState<boolean>(false);

    useEffect(() => {
        setShowAncestors(childLevelsToShow > 0);
    }, [childLevelsToShow])

    if (!node) {
        return null;
    }

    const hasAncestors = node.dam || node.sire;
    if (showAncestors && hasAncestors) {
        return (<TreeNode
            label={<StyledNode node={node} showsAncestors={true} onAncestorShowHideClicked={setShowAncestors}/>}>
            <PedigreeNode childLevelsToShow={childLevelsToShow - 1} node={node.dam}/>
            <PedigreeNode childLevelsToShow={childLevelsToShow - 1} node={node.sire}/>
        </TreeNode>)
    } else {
        return <TreeNode
            label={<StyledNode node={node} showsAncestors={false} onAncestorShowHideClicked={setShowAncestors}
                               hasAncestors={!!hasAncestors}/>}/>
    }
}

const Pedigree: FunctionComponent<PedigreeProps> = ({animal, depth = 5}) => {

    const [showRootAncestors, setShowRootAncestors] = useState<boolean>(true);

    const {data: pedigree, isLoading, isError} = useQuery(
        ['pedigree', animal?.id, depth],
        () => PedigreeService.loadPedigree(animal));

    if (isLoading) {
        return <LinearProgress />;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden des Stammbaums.</ErrorBox>
    }

    return (
        <Box sx={{
            overflowX: "auto"
        }}>
            {pedigree ? <Tree
                lineWidth={'2px'}
                lineColor={grey.A400}
                lineBorderRadius={'10px'}
                label={<StyledNode node={pedigree ? pedigree.root : null} showsAncestors={showRootAncestors}
                                   onAncestorShowHideClicked={setShowRootAncestors}/>}
            >
                {showRootAncestors && pedigree?.root.dam &&
                    <PedigreeNode childLevelsToShow={0} node={pedigree.root.dam}/>}
                {showRootAncestors && pedigree?.root.sire &&
                    <PedigreeNode childLevelsToShow={0} node={pedigree.root.sire}/>}
            </Tree> : <Typography>Keine Stammbauminformation vorhanden!</Typography>
            }
        </Box>
    );
};

export default Pedigree
