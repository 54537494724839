// @flow
import React, {Fragment, FunctionComponent} from 'react';
import {FeatureFlagService} from "../../services/feature-flag-service";


export interface FeatureFlag {
    featureFlag: string,
}

export const RenderOnFeatureFlag: FunctionComponent<FeatureFlag> = ({featureFlag, children}) =>
    FeatureFlagService.isEnabled(featureFlag) ? <Fragment>{children}</Fragment> : null;

const withFeatureFlag = <T extends object>(Component: React.ComponentType<T>, featureFlag: string) => {
    return (props: T) => {
        return (<RenderOnFeatureFlag featureFlag={featureFlag}>
            <Component {...props} />
        </RenderOnFeatureFlag>)
    }
}

export default withFeatureFlag;