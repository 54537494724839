import React, {useMemo} from 'react';
import {Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import TreatmentType from "../../../api/dtos/treatment-type";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from "react-i18next";

type TreatmentTypeSelectListProps = {
    types?: TreatmentType[];
    onTypeChange: (newType: TreatmentType) => void
    availableOptions: TreatmentType[];
};

const TreatmentTypeSelectList: React.FC<TreatmentTypeSelectListProps> = (props) => {
    const {availableOptions, onTypeChange, types = []} = props;
    const {t} = useTranslation();

    const selectedTypes = useMemo(() => new Set(types), [types]);

    return (
        <FormControl variant="standard" fullWidth>
            <List disablePadding>
                {availableOptions.map(option => {
                    const selected = selectedTypes.has(option);
                    return (
                        <ListItem key={option} disablePadding>
                            <ListItemButton dense selected={selected} onClick={() => onTypeChange(option)}>
                                <ListItemIcon><Checkbox checked={selected}/></ListItemIcon>
                                <ListItemText>{t(option)}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </FormControl>
    );
};

export default TreatmentTypeSelectList;