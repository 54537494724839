// @flow
import React, {FunctionComponent} from 'react';
import {Stack, SxProps, Theme} from "@mui/material";

interface FlexStackProps {
    sx?: SxProps<Theme>
    spacing?: number
};

const FlexStack: FunctionComponent<FlexStackProps> = ({sx,spacing = 2, children}) => {
    return (
        <Stack spacing={spacing} sx={{padding: 1, maxWidth:800, ...sx}} justifyContent="flex-start" alignItems="left">
            {children}
        </Stack>
    );
};

export default FlexStack
