// @flow
import React, {FC, Fragment} from 'react';
import {Divider, LinearProgress, List} from "@mui/material";
import AnimalRegistrationListItem from "./animal-registration-list-item";
import {RegistryService} from "../../services/registry-service";
import {RegistrationsController$RegistrationJson} from "../../api/generated/registry-rest";
import {useQuery} from "react-query";
import {ErrorBox} from "../Common/error-box";

interface AnimalRegistrationsListProps {
    animalRegistrations: RegistrationsController$RegistrationJson[]
    withDivider?: boolean
}

const useRegistries = (registrations: RegistrationsController$RegistrationJson[]) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ['registrations'],
        queryFn: () => {
            //TODO batch request
            const distinctIds = Array.from(new Set(registrations.map(r => r.registryId)));
            const registryPromises = distinctIds.map(id => RegistryService.loadById(id));
            return Promise.all(registryPromises);
        },
    });

    return {
        registries: data,
        isLoading,
        isError,
    }
};

const AnimalRegistrationsList: FC<AnimalRegistrationsListProps> = ({animalRegistrations, withDivider = false}) => {
    const {registries, isLoading, isError} = useRegistries(animalRegistrations);

    if (isLoading) {
        return <LinearProgress />
    }

    if (isError || !registries) {
        return <ErrorBox>Fehler beim Laden der Register</ErrorBox>;
    }

    return (
        <List>
            {registries.map(aR =>
                <Fragment key={aR.id}>
                    <AnimalRegistrationListItem registrationListDto={aR}/>
                    {withDivider && <Divider variant="middle" component="li"/>}
                </Fragment>
            )}
        </List>
    );
};

export default AnimalRegistrationsList
