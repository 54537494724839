// @flow
import {Box, Chip} from '@mui/material';
import React, {FunctionComponent} from 'react';

interface ChipsListProps {
    listItems: string[],
    onItemClick?: (item: string) => void
}

const ChipsList: FunctionComponent<ChipsListProps> = ({listItems, onItemClick}) => {
    const handleChipClick = (e: React.MouseEvent<HTMLDivElement | undefined>, item: string) => {
        onItemClick?.(item)
        e.stopPropagation();
    }

    return (
        <Box>
            {listItems.map(item => <Chip key={item}
                                         sx={{marginRight: 1}}
                                         label={item}
                                         clickable
                                         color="primary"
                                         onClick={(e) => handleChipClick(e, item)}
            />)}
        </Box>
    );
};

export default ChipsList