// @flow
import React, {FunctionComponent} from 'react';
import {Widget} from "../../Common";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {PregnancyListJson} from "../../../api/generated/medical-rest";
import {ParentTypeValues} from "../../../api/generated/herds-pedigree";
import MatingList from "./mating-list";

type Props = {
    animal: JsonAnimal,
    matings: PregnancyListJson[]
};


const MatingWidget: FunctionComponent<Props> = ({animal,matings, children}) => {
    return (
        <Widget displayName="Deckungen">
            {matings.length
                ? <MatingList pregnancies={matings} baseParentKind={ParentTypeValues.SIRE}/>
                : 'Keine Deckungen vorhanden.'}
        </Widget>
    );
};

export default MatingWidget