// @flow
import React, {FunctionComponent, useState} from 'react';
import {Stack, Typography} from "@mui/material";
import {SearchField} from "../Common";
import {BreederService} from "../../services/breeder-service";
import BreederList from "../Common/breeder-list";
// @ts-ignore
import {RegistryService, RegistryUserType} from "../../services/registry-service";
import ConfirmDialog from "../Common/confirm-dialog";
import {useParams} from "react-router-dom";
import RouteService from "../../services/route-service";
import OnCloseNavigationSnackbar from "../Common/on-close-navigation-snackbar";
import {BreederSearchResult} from "../../api/generated/herd-breeder";

interface Props {
    userType: RegistryUserType
}

interface AddRegistryUserRouteParams {
    id: string;
}


const AddRegistryUser: FunctionComponent<Props> = ({userType}) => {

    const registryId = useParams<AddRegistryUserRouteParams>().id;

    const [userSearchResults, setUserSearchResults] = useState<BreederSearchResult[]>([]);
    const [user, setUser] = useState<BreederSearchResult | null>(null);
    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
    const [selectedBreeder, setSelectedBreeder] = useState<BreederSearchResult>({id: "", username: "", email: "", herdCode: "", farmName: "", website: "", owners: []});
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);


    const findUserSuggestions = async (searchTerm: string) => {
        setUserSearchResults(searchTerm ? await BreederService.search(searchTerm) : []);
        return []; //no suggestions are needed as we display the search results right below the search field already
    }

    const handleListItemClicked = (breeder: BreederSearchResult) => {
        setSelectedBreeder(breeder);
        setOpenAddDialog(true);
    };
    const handleAddDialogClose = () => {
        setOpenAddDialog(false);
    }

    const handleAssignBreeder = (breeder: BreederSearchResult) => {
        RegistryService.assignBreeder(registryId, breeder.id, userType);
        setSnackbarOpen(true);

    }

    return (
        <Stack spacing={3} justifyContent="flex-start" alignItems="left">
            <ConfirmDialog onClose={handleAddDialogClose} open={openAddDialog}
                           title={`Möchtest du diesen Züchter als ${RegistryService.getUserTypeString(userType)} hinzufügen?`}
                           okButtonText="Hinzufügen"
                           onOk={() => handleAssignBreeder(selectedBreeder)}>
                <BreederList breeders={[selectedBreeder]}/>
            </ConfirmDialog>
            <Typography variant="h3" align="center">{RegistryService.getUserTypeString(userType)} hinzufügen</Typography>
            <SearchField<BreederSearchResult> showSearchIcon={true} findSuggestions={findUserSuggestions} renderOption={(r) => r.herdCode} optionUniqueKey={(r) => r.id}
                                              value={user}
                                              onValueChange={setUser}/>
            <BreederList onListItemClick={handleListItemClicked} breeders={userSearchResults}/>
            <OnCloseNavigationSnackbar message="Benutzer hinzugefügt!" triggerSnackbar={snackbarOpen} expandedRoute={RouteService.expand(RouteService.REGISTRY_ADMINISTRATION_DETAILS, {id: registryId})} />
        </Stack>
    );
};

export default AddRegistryUser
