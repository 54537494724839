// @flow
import React, {Fragment, FunctionComponent} from 'react';
import {Divider, List} from "@mui/material";
import BreederListItem from "./breeder-list-item";
import {BreederSearchResult} from "../../api/generated/herd-breeder";

interface BreederListProperties {
    breeders: BreederSearchResult[];
    onListItemClick?: (breeder: BreederSearchResult) => void
    onListItemDelete?: (breeder: BreederSearchResult) => void
};

const BreederList: FunctionComponent<BreederListProperties> = ({breeders, onListItemClick, onListItemDelete}) => {
    return (
        <List>
            {breeders.map(breeder => <Fragment key={breeder.id}><BreederListItem onClick={onListItemClick} breeder={breeder} onDelete={onListItemDelete}/><Divider variant="middle" component="li"/></Fragment>)}
        </List>
    );
};

export default BreederList;