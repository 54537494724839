import React, {FC} from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import FormattedDate from '../Common/formatted-date';
import {SxProps, Theme} from "@mui/material";
import {useHistory} from "react-router-dom";
import RouteService from "../../services/route-service";
import I18NService from "../../services/i18n-service";
import {JsonAnimal} from "../../api/generated/rest-dto";

interface AnimalCardProps {
  data: JsonAnimal
  withImage?: boolean
  sx?: SxProps<Theme>
}

const styles: Record<string, SxProps> = {
  card: {
    display: 'inline-block'
  },
  media: {
    height: 300
  },
  infolist: {
    margin: 0,
    padding: 0
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left',
    fontWeight: 'bold'
  }
};

const EMPTY_SX = {} as const;

const AnimalCard:FC<AnimalCardProps> = ({data, withImage = false, sx = EMPTY_SX}) =>  {
  const history = useHistory();

  const findImageLink = (animalId: string) => {
    switch(animalId) {
      case "76816a3c-f9a2-41b3-bcc1-02525197bd9d": return "/camille2.jpg"
      case "242378d2-4a70-4ffc-86e8-b98b927abd1a": return "/leopold.jpg"
      case "ff28b5d5-cf73-4d93-aa7b-b8d72cefb721": return "/frosty.jpg"
      default: return ""
    }
  }


  const handleCardClick = (animalData: JsonAnimal) => {
    history.push({pathname: RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: animalData.panonIdentifier.id})});
  };

  const imageLink = withImage && findImageLink(data.id);

  return (
    <Card sx={[styles.card, ...(Array.isArray(sx) ? sx : [sx])]}>
      <CardActionArea onClick={() => handleCardClick(data)}>
        {imageLink && <CardMedia
          sx={styles.media}
          image={imageLink}
          title={data.name}
        />}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {data.herdCode} {data.name}
          </Typography>
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography sx={styles.left} component="span">
                    Geschlecht:
                  </Typography>
                </td>
                <td>
                  <Typography sx={styles.right} component="span">
                    {I18NService.translateSex(data.sex)}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography sx={styles.left} component="span">
                    Geburtsdatum:
                  </Typography>
                </td>
                <td>
                  <FormattedDate sx={styles.right} date={data.dateOfBirth} />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography sx={styles.left} component="span">
                    Farbe:
                  </Typography>
                </td>
                <td>
                  <Typography sx={styles.right} component="span">
                    {I18NService.translateFiberColor(data.fiberColor)}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography sx={styles.left} component="span">
                    Id:
                  </Typography>
                </td>
                <td>
                  <Typography sx={styles.right} component="span">
                    {data.chipId}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}


export default AnimalCard;
