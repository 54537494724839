// @flow
import React, {FunctionComponent} from 'react';
import {Box, Card, CardActionArea, CardContent, CardHeader, SxProps} from "@mui/material";

interface FlexCardProps {
    title: string
    clickable?: boolean
    onClick?: () => void
    sx?: SxProps
    headerActions?: React.ReactNode
}

const FlexCard: FunctionComponent<FlexCardProps> = ({title, clickable = false, onClick = () => {}, sx, headerActions, children}) => {

    const cardContent = (
        <CardContent sx={{flex: '1 0 auto'}}>
            {children}
        </CardContent>
    );


    return (
        <Card>
            <CardHeader title={title} action={headerActions}/>
            <Box sx={{display: 'flex', flexDirection: 'column', ...sx}}>
                {clickable
                    ? <CardActionArea onClick={onClick}>{cardContent}</CardActionArea>
                    : cardContent
                }
            </Box>
        </Card>
    );
};

export default FlexCard
