// @flow
import React, {FunctionComponent, useState} from 'react';
import FlexStack from "../components/Common/flex-stack";
import useFormFields from "../components/Common/hooks/use-form-fields";
import {SignUpBreeder} from "../api/generated/breeder-controller";
import {Box, Button, TextField, Typography} from "@mui/material";
import AuthService from "../services/auth-service";
import OnCloseNavigationSnackbar from "../components/Common/on-close-navigation-snackbar";
import RouteService from "../services/route-service";
import {useTranslation} from "react-i18next";

const SignupBreeder: FunctionComponent = () => {
    const {formFields, createChangeHandler} = useFormFields<SignUpBreeder>({email: "", herdCode: "", password: "", username: ""});
    const {t} = useTranslation();
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

    const handleRegisterClick = async () => {
        await AuthService.signUp(formFields);
        setShowSnackbar(true);
    }

    return (
        <FlexStack>
            <Box>
                <Typography variant="h4">Registriere dich als Züchter</Typography>
            </Box>
            <TextField id="test-herdcode" label="Herdkürzel" variant="standard" value={formFields.herdCode} onChange={createChangeHandler("herdCode")}/>
            <TextField id="test-email" label="Email" type="email" variant="standard" value={formFields.email} onChange={createChangeHandler("email")}/>
            <TextField id="test-username" label="Benutzername" variant="standard" value={formFields.username} onChange={createChangeHandler("username")}/>
            <TextField id="test-password" label="Passwort" type="password" variant="standard" value={formFields.password} onChange={createChangeHandler("password")}/>
            <Button variant="contained" onClick={handleRegisterClick}>Registrieren</Button>
            <OnCloseNavigationSnackbar triggerSnackbar={showSnackbar} message={t("breeder_create_success")} expandedRoute={RouteService.HOME}/>
        </FlexStack>
    );
};

export default SignupBreeder
