// @flow
import React, {FunctionComponent} from 'react';
import HelpArticleEditor from "../components/Help/help-article-editor";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useParams} from "react-router-dom";
import {Typography} from '@mui/material';
import {HelpService} from "../services/help-service";
import {HelpArticle} from "../api/generated/herds-support";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import RouteService from "../services/route-service";

const HelpEditPage: FunctionComponent = () => {
    const {articleId} = useParams<{ articleId: string }>();
    const queryClient = useQueryClient();
    const {error, success} = useGlobalSnackbarStore((state) => state);

    const {data: article} = useQuery({
        queryKey: ["helpArticleById", articleId],
        queryFn: async () => HelpService.getById(articleId),
        enabled: !!articleId,
    });

    const updateArticleMutation = useMutation(
        async (updatedArticle: HelpArticle) => HelpService.update(updatedArticle),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("helpArticles");
                queryClient.invalidateQueries(["helpArticleById", articleId]);
                success("Gespeichert", RouteService.HELP);
            },
            onError: (e) => {
                error("Fehler: " + e)
            }
        }
    );

    const createArticleMutation = useMutation(
        async (newArticle: HelpArticle) => HelpService.create(newArticle),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("helpArticles");
                success("Gespeichert", RouteService.HELP);
            },
            onError: (e) => {
                error("Fehler: " + e)
            }
        }
    );


    if (!articleId)
        return <HelpArticleEditor onSave={(newArticle) => createArticleMutation.mutate(newArticle)}/>

    if (article)
        return <HelpArticleEditor article={article} onSave={(updatedArticle) => updateArticleMutation.mutate(updatedArticle)}/>

    return <Typography variant="h6">Loading article...</Typography>


};

export default HelpEditPage;
