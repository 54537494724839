// @flow
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {AnimalService} from "../services/animal-service";
import FlexStack from "../components/Common/flex-stack";
import BaseData from "../components/AnimalDashboard/base-data-widget";
import PedigreeWidget from "../components/AnimalDashboard/Pedigree/pedigree-widget";
import {JsonAnimal} from "../api/generated/rest-dto";
import {RegistryService} from "../services/registry-service";
import {RegistrationService} from "../services/registration-service";
import ExistingRegistrationsCard from "../components/AnimalReview/existing-registrations-card";
import {
    RegistrationsController$RegistrationEditJson,
    RegistrationsController$RegistrationJson
} from "../api/generated/registry-rest";
import RouteService from "../services/route-service";
import {AnimalBaseData, transformFromRegistration} from "../api/dtos/animal-base-data";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import {OwnerWidget} from "../components/AnimalDashboard/owner-widget";

interface AnimalDetailsViewOnlyPathProps {
    panonId: string;
    registrationStatus: string;
}


const RegisteredAnimalDetails: FunctionComponent = () => {

    const {panonId, registrationStatus} = {...useParams<AnimalDetailsViewOnlyPathProps>()};
    const [animal, setAnimal] = useState<JsonAnimal>();
    const [registryWarden, setRegistryWarden] = useState(false);
    const [registrations, setRegistrations] = useState<RegistrationsController$RegistrationJson[]>([]);

    const {error, success} = useGlobalSnackbarStore((state) => state);

    const initializeFromRegisteredAnimal = async (pId: string) => {
        let registrations = await RegistrationService.findRegistrationsByPanonId(pId);
        setRegistrations(registrations);
        const animalDto = await AnimalService.loadJsonAnimalByPanonId(pId);
        setAnimal(animalDto);
    }

    const initializePedigreeEditable = async (pId: string) => {
        const wardenRegistries = await RegistryService.findAllWhereBreederIsWarden();
        setRegistryWarden(await RegistrationService.registrationForAnyRegistryExists(wardenRegistries.map(wR => wR.id), pId));
    }

    const handleBaseDataEdit = async (animalBaseData: AnimalBaseData) => {
        const registration = registrations[0];
        const editData: RegistrationsController$RegistrationEditJson = {
            breed: animalBaseData.breed,
            dateOfBirth: animalBaseData.dateOfBirth,
            eyeColor: registration.phenotype.eyeColor,
            herdCode: animalBaseData.herdCode,
            name: animalBaseData.name,
            primaryColor: animalBaseData.fiberColor,
            secondaryColors: registration.phenotype.secondaryColors,
            sex: animalBaseData.sex
        };
        try {
            await RegistrationService.editRegistration(registration, editData);
            success("Daten erfolgreich aktualisiert!");
        } catch (e) {
            error("Aktualisierung fehlgeschlagen!");
        }
    }

    useEffect(() => {
        void initializeFromRegisteredAnimal(panonId);
        void initializePedigreeEditable(panonId);
    }, [panonId])

    if (!animal) {
        return <div></div>
    }


    return (
        <FlexStack key={animal.id}>
            <BaseData onSubmit={handleBaseDataEdit} editable={registryWarden}
                      initialBaseData={transformFromRegistration(registrations[0])}/>
            <ExistingRegistrationsCard existingRegistrations={registrations}/>
            <OwnerWidget ownerId={animal.ownerId} />
            <PedigreeWidget isRegistered={registrations.length > 0} animal={animal} isRegistryWarden={registryWarden}
                            expandedRedirectUrl={RouteService.expand(RouteService.REGISTERED_ANIMAL_DETAILS, {
                                panonId,
                                registrationStatus
                            })}/>
        </FlexStack>
    );
};

export default RegisteredAnimalDetails
