import {useHistory} from "react-router-dom";
import RouteService from "../../../services/route-service";
import {useCallback} from "react";

/**
 * Custom hook to simplify pushing to history.
 */
export const useNav = () => {
    const history = useHistory();
    const push = useCallback(
        (routeTemplate: string, parameters: Record<string, string> = {}, state?: unknown) => history.push(RouteService.expand(routeTemplate, parameters), state),
        [history]);
    return {
        push,
        history,
    };
}