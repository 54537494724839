import {ApiConfigPort} from '../config/api-config-port';
import {HttpService} from '../../services/http-service';
import {DeleteResult, Result, SingleResult} from './lib-rest';

export interface JsonPedigree {
  dam: JsonPedigree;
  panonId: string;
  sire: JsonPedigree;
}

export interface JsonPedigreeRecord {
  damId: string;
  panonId: string;
  sireId: string;
}

export class PedigreeApi {
  config: ApiConfigPort;


 public  async addToPedigree(id: string): Promise<Result>  {
const url =  new URL('/pedigree/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString())).data
  }

 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async create(id: string, dam: string, sire: string): Promise<Result>  {
const url =  new URL('/pedigree/_new/' + id + '/' + dam + '+' + sire + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async deleteAnimal(panonId: string): Promise<DeleteResult>  {
const url =  new URL('/pedigree/' + panonId + '', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async deleteDam(panonId: string): Promise<DeleteResult>  {
const url =  new URL('/pedigree/' + panonId + '/dam', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async deleteSire(panonId: string): Promise<DeleteResult>  {
const url =  new URL('/pedigree/' + panonId + '/sire', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async getById(id: string): Promise<SingleResult<JsonPedigreeRecord> >  {
const url =  new URL('/pedigree/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getFullPedigreeById(id: string): Promise<SingleResult<JsonPedigree> >  {
const url =  new URL('/pedigree/' + id + '/pedigree/full', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getPedigreeById(id: string): Promise<SingleResult<JsonPedigree> >  {
const url =  new URL('/pedigree/' + id + '/pedigree', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async setPedigreeRecord(pedigreeRecord: JsonPedigreeRecord): Promise<Result>  {
const url =  new URL('/pedigree', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString(),JSON.stringify(pedigreeRecord))).data
  }

}

