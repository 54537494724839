import React, {Suspense, useMemo, useState} from 'react';
import {Alert, Chip, LinearProgress, Stack, SxProps, Tab, Tabs, Typography} from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {Link as RouterLink, RouteComponentProps, withRouter} from "react-router-dom";
import {JsonAnimal} from "../api/generated/rest-dto";
import RouteService from "../services/route-service";
import FlexStack from "../components/Common/flex-stack";
import {SearchField, TabPanel} from "../components/Common";
import {useTranslation} from "react-i18next";
import AnimalList from "../components/Animal/animal-list";
import {AnimalRecordStatus, SexValues} from "../api/generated/herd-animal";
import {ErrorBox} from "../components/Common/error-box";
import List from "@mui/material/List";
import {useTabs} from "../components/Common/hooks/use-tabs";
import {LoadingAvatarListItem} from "../components/Common/loading-avatar-list-item";
import {useHerdPregnancies} from "./herd-pregnancies";
import {PregnancyListJson} from "../api/generated/medical-rest";
import {withErrorBoundary} from 'react-error-boundary';
import {useHerd} from '../components/Common/hooks/use-herd';
import {DateTime} from "luxon";


const styles: Record<string, SxProps> = {
    fab: {
        position: "fixed",
        bottom: 5,
        right: 5,
    },
    animalBox: {
        pr: 2
    },
    animalCard: {
        w: "100%"
    }
} as const;

type HerdProps = {
    groupId: string;
    recordStatus: AnimalRecordStatus;
};

const CREATE_ANIMAL = {
    pathname: RouteService.ANIMAL_CREATE,
    state: {createForHerd: true}
};

const LoadingFallback = () => (
    <Stack>
        <LinearProgress/>

        <Tabs value={0}>
            <Tab label={`Alle (...)`}/>
        </Tabs>

        <TabPanel value={0} index={0}>
            <List>
                <LoadingAvatarListItem/>
                <LoadingAvatarListItem/>
                <LoadingAvatarListItem/>
            </List>
        </TabPanel>
    </Stack>
);

const TabbedHerd = ({animals, onAnimalClick, onTagClick}: {
    animals: (JsonAnimal & { pregnancy?: PregnancyListJson;})[],
    onAnimalClick: (animal: JsonAnimal) => void;
    onTagClick: (tag: string) => void
}) => {
    const [selectedTab, setSelectedTab] = useTabs();

    const oneYearAgo = useMemo(() => DateTime.now().minus({year: 1}), []);
    const dams = useMemo(() => animals.filter(a => a.sex === SexValues.FEMALE), [animals]);
    const sires = useMemo(() => animals.filter(a => a.sex === SexValues.MALE), [animals]);
    const youngAnimals = useMemo(() => animals.filter(a => a.dateOfBirth >= oneYearAgo), [animals, oneYearAgo]);
    const oldAnimals = useMemo(() => animals.filter(a => a.dateOfBirth < oneYearAgo), [animals, oneYearAgo]);

    return (
        <>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <Tab label={`Alle (${animals.length})`}/>
                <Tab label={`Stuten (${dams.length})`}/>
                <Tab label={`Hengste (${sires.length})`}/>
                <Tab label={`< 1J. (${youngAnimals.length})`}/>
                <Tab label={`> 1J. (${oldAnimals.length})`}/>
            </Tabs>

            <TabPanel value={selectedTab} index={0}>
                <AnimalList
                    items={animals}
                    onItemClick={onAnimalClick}
                    onTagClick={onTagClick}
                    showTags/>
            </TabPanel>

            <TabPanel value={selectedTab} index={1}>
                <AnimalList
                    items={dams}
                    onItemClick={onAnimalClick}
                    onTagClick={onTagClick}
                    showTags/>
            </TabPanel>

            <TabPanel value={selectedTab} index={2}>
                <AnimalList
                    items={sires}
                    onItemClick={onAnimalClick}
                    onTagClick={onTagClick}
                    showTags/>
            </TabPanel>

            <TabPanel value={selectedTab} index={3}>
                <AnimalList
                    items={youngAnimals}
                    onItemClick={onAnimalClick}
                    onTagClick={onTagClick}
                    showTags/>
            </TabPanel>

            <TabPanel value={selectedTab} index={4}>
                <AnimalList
                    items={oldAnimals}
                    onItemClick={onAnimalClick}
                    onTagClick={onTagClick}
                    showTags/>
            </TabPanel>
        </>
    );
};

const TagFilter = ({tags, activeTag, onTagClick}: {
    tags: string[],
    activeTag: string,
    onTagClick?: (item: string) => void
}) => {
    const distinctTags = useMemo(() => Array.from(new Set(tags)), [tags]);
    const countByTag = useMemo(() => {
        const map = new Map<string, number>();
        for (const tag of tags) {
            const count = map.get(tag) ?? 0;
            map.set(tag, count + 1);
        }
        return map;
    }, [tags]);

    const handleReset = () => onTagClick?.('all');
    const handleClick = (tag: string) => tag === activeTag ? handleReset() : onTagClick?.(tag);

    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <Typography>Tags filtern:</Typography>
            <List>
                {distinctTags.map(tag => <Chip key={tag}
                                               sx={{mr: 1}}
                                               label={`${tag} (${countByTag.get(tag)})`}
                                               clickable
                                               variant={tag === activeTag ? 'filled' : 'outlined'}
                                               color="primary"
                                               onClick={() => handleClick(tag)}
                />)}
                {activeTag !== 'all' &&
                    <Chip label={`Filter zurücksetzen (${tags.length})`} onClick={handleReset} onDelete={handleReset}/>}
            </List>
        </Stack>
    );
};

const FilteredHerd = ({params: {groupId, recordStatus}, searchTerm, onAnimalClick, onTagClick}: {
    params: HerdProps,
    searchTerm: string;
    onAnimalClick: (animal: JsonAnimal) => void;
    onTagClick: (tag: string) => void
}) => {
    const {herd = []} = useHerd(recordStatus);
    const {pregnancies: herdPregnancies, isLoading, isError} = useHerdPregnancies();

    const tags = useMemo(() => herd.flatMap(a => a.tags), [herd]);
    const animals = useMemo(() => {
        return herd
            .filter(a => groupId === 'all' || a.tags.includes(groupId))
            .filter(a => {
                const lowercasedSearchTerm = searchTerm?.toLowerCase() ?? '';
                return a.name.toLowerCase().includes(lowercasedSearchTerm) || a.tags.includes(lowercasedSearchTerm)
            });
    }, [herd, groupId, searchTerm]);

    if (isLoading) {
        return <LoadingFallback/>;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Herde</ErrorBox>;
    }

    if (!animals.length) {
        return (
            <Alert severity="info">
                Keine Tiere in der Herde. <RouterLink to={CREATE_ANIMAL}>Erstes Tier hinzufügen</RouterLink>.
            </Alert>
        );
    }

    const pregnanciesByDam: Map<string, PregnancyListJson> = new Map();
    herdPregnancies?.forEach(pregnancy => pregnanciesByDam.set(pregnancy.damId, pregnancy));

    const animalsWithPregnancy: (JsonAnimal & { pregnancy?: PregnancyListJson })[] = animals.map(dam => {
        const pregnancy = pregnanciesByDam.get(dam.panonIdentifier.id);
        return {
            ...dam,
            pregnancy: pregnancy,
        };
    });

    return (
        <>
            <TagFilter tags={tags} activeTag={groupId} onTagClick={onTagClick}/>

            <TabbedHerd animals={animalsWithPregnancy}
                        onAnimalClick={onAnimalClick}
                        onTagClick={onTagClick}/>
        </>
    );
};

const Herd: React.FC<RouteComponentProps<HerdProps>> = ({match, history}) => {
    const params = match.params;

    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('')

    const performSearch = async (searchTerm: string): Promise<JsonAnimal[]> => {
        setSearchTerm(searchTerm);
        return []; //we don't show suggestions on the searchfield here, so [] is returned
    }

    const handleAnimalClick = (animalData: JsonAnimal) => {
        history.push({pathname: RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: animalData.panonIdentifier.id})});
    };

    const handleTagClick = (tag: string) => {
        history.push(RouteService.expand(RouteService.HERD_GROUP, {groupId: tag, recordStatus: params.recordStatus}));
    };

    return (
        <FlexStack>
            <SearchField<JsonAnimal> label={t("herd.filter")} findSuggestions={performSearch}
                                     renderOption={a => a.herdCode + a.name}
                                     optionUniqueKey={a => a.panonIdentifier.id} value={null} onValueChange={() => {}}/>

            <Suspense fallback={<LoadingFallback/>}>
                <FilteredHerd
                    params={params}
                    onAnimalClick={handleAnimalClick}
                    searchTerm={searchTerm}
                    onTagClick={handleTagClick}/>
            </Suspense>
            <Fab className="fab-create-animal" color="primary" variant="circular" sx={styles.fab} component={RouterLink}
                 to={CREATE_ANIMAL}>
                <AddIcon/>
            </Fab>
        </FlexStack>
    );
};

export default withRouter(
    withErrorBoundary(
        Herd,
        {
            fallback: <ErrorBox>Fehler beim Anzeigen der Herde</ErrorBox>,
        }
    )
);
