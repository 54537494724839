// @flow
import React, {FunctionComponent} from 'react';
import FlexCard from "../AnimalReview/flex-card";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import AnimalBaseDataForm from "../AnimalCreate/animal-base-data-form";
import {AnimalBaseData} from "../../api/dtos/animal-base-data";

interface PedigreeAnimalCreateCardProps {
    onSubmit: (animalBaseData: AnimalBaseData) => void
};
const PedigreeAnimalCreateCard: FunctionComponent<PedigreeAnimalCreateCardProps> = ({onSubmit}) => {

    const [expanded, setExpanded] = React.useState<boolean>(false);

    const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded);
        };

    return (
        <FlexCard title="Kein Suchergebnis">
            <Box sx={{display:"flex", justifyContent: "center"}}>

                <Accordion expanded={expanded} onChange={handleChange}>
                    <AccordionSummary
                        expandIcon={<AddCircleOutlineIcon fontSize="large"/>}
                        aria-controls="animal-create-panel-content"
                        id="animal-create-panel-header"
                    >
                        <Typography>
                            Externes Elterntier anlegen (erscheint nicht in Ihrer Herde)
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                     <AnimalBaseDataForm onSubmitClicked={onSubmit}/>
                    </AccordionDetails>
                </Accordion>

            </Box>
        </FlexCard>
    );
};

export default PedigreeAnimalCreateCard