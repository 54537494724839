import React, {FC, useState} from 'react';
import {Box, Button, LinearProgress, SxProps, TextField} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import TagService from "../services/tag-service";
import FlexStack from "../components/Common/flex-stack";
import {useHistory, useParams} from "react-router-dom";
import RouteService from "../services/route-service";
import {useAnimal} from "../components/Common/hooks/use-animal";
import {useQuery} from "react-query";

const useTags = () => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['tags'],
        queryFn: () => TagService.loadTags(),
    });

    return {
        tags: data ?? [],
        isLoading,
        isError,
    };
};

interface TagEditorPathParams {
    panonId: string;
}

const styles: Record<string, SxProps> = {
    button: {m: 2}
};

const TagAutocomplete = ({animalId, tags, onClose}: {
    animalId: string,
    tags: string[],
    onClose?: () => void,
}) => {
    const [tagNames, setTagNames] = useState(tags);
    const {tags: availableTags} = useTags();

    const handleSaveClicked = async () => {
        await TagService.setTags({animalId, tagNames});
        onClose?.();
    };

    return (
        <FlexStack>
            <Autocomplete
                className="tags-input"
                options={availableTags}
                multiple
                freeSolo
                renderInput={(params: any) => <TextField {...params} label="Tags" variant="outlined"/>}
                value={tagNames}
                onChange={(event, newValue) => setTagNames(newValue)}
            />
            <Box>
                <Button className="tags-cancel-button" sx={styles.button} variant="outlined" color="primary"
                        onClick={onClose}>Abbrechen</Button>
                <Button className="tags-save-button" sx={styles.button} variant="contained" color="primary"
                        onClick={handleSaveClicked}>Speichern</Button>
            </Box>
        </FlexStack>
    );
};

const TagEditor: FC = () => {
    const history = useHistory();
    const {panonId} = useParams<TagEditorPathParams>();
    const {animal, isLoading} = useAnimal(panonId);

    if (isLoading) {
        return <LinearProgress/>
    }

    if (!animal) {
        return null;
    }

    const handleClose = () => history.push(RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId}));

    return <TagAutocomplete animalId={animal.id} tags={animal.tags} onClose={handleClose}/>;
}

export default TagEditor;
