// @flow
import React, {FunctionComponent, useEffect, useState} from 'react';
import {Button, Snackbar} from "@mui/material";
import {useHistory} from "react-router-dom";

interface OnCloseNavigationSnackbarProperties {
    triggerSnackbar: boolean,
    expandedRoute?: string,
    message?: string
}

const OnCloseNavigationSnackbar: FunctionComponent<OnCloseNavigationSnackbarProperties> = ({triggerSnackbar, expandedRoute, message = "Aktion erfolgreich"}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (triggerSnackbar) {
            setSnackbarOpen(triggerSnackbar);
        }
    }, [triggerSnackbar])

    const handleSnackbarClose = async () => {
        setSnackbarOpen(false);
        if (expandedRoute) {
            history.push(expandedRoute);
        } else {
            history.goBack();
        }
    }

    return (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
            message={message}
            action={
                <Button color="secondary" size="small" onClick={handleSnackbarClose}>
                    OK
                </Button>}
        />
    );
};

export default OnCloseNavigationSnackbar