// @flow
import React, {FunctionComponent, useEffect, useState} from 'react';
import {Avatar, Card, Grid, Stack, Typography} from "@mui/material";
import {useLocation, useParams} from "react-router-dom";
import RegistrationReviewService from "../../services/registration-review-service";
import {DateTime} from "luxon";
import {JsonAnimal} from "../../api/generated/rest-dto";
import ReviewSimilaritiesComparisonDto from "../../api/dtos/review-similarities-comparison-dto";
import {AnimalService} from "../../services/animal-service";

interface SimilarityComparisonPathParams {
    reviewedAnimalPanonId: string,
    similarAnimalPanonId: string
}

interface SimilarityComparisonStateParams {
    reviewedAnimal: JsonAnimal,
    similarAnimal: JsonAnimal
}


const SimilarityComparison: FunctionComponent = () => {
    const pathParams = useParams<SimilarityComparisonPathParams>();
    const locationState = useLocation<SimilarityComparisonStateParams>().state;
    let reviewedAnimal: JsonAnimal;
    let similarAnimal: JsonAnimal;
    if(locationState){
        reviewedAnimal= locationState.reviewedAnimal;
        similarAnimal = locationState.similarAnimal;
    }

    const [similarities, setSimilarities] = useState<ReviewSimilaritiesComparisonDto>();

    const initialize = async () => {
        if(!reviewedAnimal) {
            reviewedAnimal = await AnimalService.loadJsonAnimalByPanonId(pathParams.reviewedAnimalPanonId);
        }
        if(!similarAnimal){
            similarAnimal = await AnimalService.loadJsonAnimalByPanonId(pathParams.similarAnimalPanonId);
        }
        setSimilarities(await RegistrationReviewService.loadSimilarityComparison(reviewedAnimal, similarAnimal));
    }

    const renderAsString = (object: any): string => {
        if(!object) return "";
        if (DateTime.isDateTime(object)) {
            return (object as DateTime).toLocaleString()
        }
        return object.toString();
    }

    useEffect(() => {
        initialize();
    }, [pathParams.reviewedAnimalPanonId, pathParams.similarAnimalPanonId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Stack spacing={1}>
            {similarities &&

                <Grid sx={{maxWidth: 800}} container justifyContent="space-between">
                    <Grid item xs={6} display="flex" justifyContent="center">
                        <Avatar variant="square" sx={{height: 100, width: 100}} src={similarities.avatarUrlReviewAnimal}/>
                    </Grid>
                    <Grid item display="flex" justifyContent="center" xs={6}>
                        <Avatar variant="square" sx={{height: 100, width: 100}} src={similarities.avatarUrlSimilarAnimal}/>
                    </Grid>
                </Grid>

            }
            {similarities ? similarities.comparisonPairs.map(s => {
                    let fw = "regular";
                    let color = "";
                    if (s.isEqual()) {
                        fw = "bold";
                        color = "error";
                    }
                    return (<Card sx={{maxWidth: 800, marginLeft: 1, marginRight: 1, textAlign: "center"}}>
                        <Typography variant="h6">{s.name}</Typography>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={6}>
                                <Typography fontWeight={fw} color={color} variant="subtitle1">{renderAsString(s.firstValue)}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={fw} color={color} variant="subtitle1">{renderAsString(s.secondValue)}</Typography>
                            </Grid>
                        </Grid>
                    </Card>)
                }) :
                null

            }
        </Stack>
    );
};

export default SimilarityComparison
