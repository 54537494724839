// @flow
import React, {Fragment, FunctionComponent} from 'react';
import {Divider, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {RegistryDto} from "../../api/generated/registry-service";

interface Props {
    registries: RegistryDto[],
    onListItemClick: (registry: RegistryDto) => void
}

const RegistryList: FunctionComponent<Props> = ({registries, onListItemClick}) => {
    return (
        <List>
            {registries.map(registry =>
                <Fragment key={registry.id}>
                    <ListItem>
                        <ListItemButton onClick={() => onListItemClick(registry)}>
                            <ListItemText primary={registry.name}/>
                        </ListItemButton>
                    </ListItem>
                    <Divider variant="middle" component="li"/>
                </Fragment>
            )}
        </List>
    );
};

export default RegistryList