import React, {FC, useState} from 'react';
import {Box, Button, Stack, SxProps, TextField} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import RouteService from "../../services/route-service";
import OnCloseNavigationSnackbar from "../Common/on-close-navigation-snackbar";
import {RegistryService} from "../../services/registry-service";

const CreateRegistry: FC = () => {
    const styles = {
        root: {
            '& .MuiTextField-root': {
                margin: 1
            },
            display: "flex",
            flexDirection: "column",
            maxWidth: 500
        } as SxProps,
        button: {
            margin: 1,
        } as SxProps,
        formControl: {
            margin: 1,
            minWidth: 120,
        } as SxProps
    };

    const [name, setName] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const onCreateClicked = async () => {
        //TODO: error handling (e.g. registry with that name already exists, empty name is not allowed)
        await RegistryService.createRegistry(name);
        //give feedback
        setSnackbarOpen(true);
    };

    return (<Stack justifyContent="space-between">

            <form noValidate autoComplete="off">
                <Box sx={styles.root}>
                    <TextField id="registry-creation-name" error={false} required
                               value={name}
                               onChange={(event) => setName(event.target.value)}
                               label="Register Name"
                    />
                </Box>


            </form>
            <Box display="flex" justifyContent="center">
            <Button
                variant="text"
                size="large"
                sx={styles.button}
                component={RouterLink}
                to={{pathname: RouteService.REGISTRY_SEARCH}}
            >
                Abbrechen
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="large"
                sx={styles.button}
                onClick={onCreateClicked}
            >
                Erstellen
            </Button>
            </Box>
            <OnCloseNavigationSnackbar message="Register erstellt!" triggerSnackbar={snackbarOpen} expandedRoute={RouteService.REGISTRY_SEARCH} />
        </Stack>
    )
};

export default CreateRegistry;
