
export type AlpacaBreed = 'SURI' | 'HUACAYA'
export class AlpacaBreedValues {
static readonly   HUACAYA: AlpacaBreed = 'HUACAYA';
static readonly   SURI: AlpacaBreed = 'SURI';
};

export type AlpacaFiberColor = 'UNKNOWN' | 'WHITE' | 'BEIGE' | 'LIGHT_FAWN' | 'MEDIUM_FAWN' | 'DARK_FAWN' | 'LIGHT_BROWN' | 'MEDIUM_BROWN' | 'DARK_BROWN' | 'BAY_BLACK' | 'TRUE_BLACK' | 'LIGHT_SILVER_GREY' | 'MEDIUM_SILVER_GREY' | 'DARK_SILVER_GREY' | 'LIGHT_ROSE_GREY' | 'MEDIUM_ROSE_GREY' | 'DARK_ROSE_GREY' | 'MULTICOLOR'
export class AlpacaFiberColorValues {
static readonly   BAY_BLACK: AlpacaFiberColor = 'BAY_BLACK';
static readonly   BEIGE: AlpacaFiberColor = 'BEIGE';
static readonly   DARK_BROWN: AlpacaFiberColor = 'DARK_BROWN';
static readonly   DARK_FAWN: AlpacaFiberColor = 'DARK_FAWN';
static readonly   DARK_ROSE_GREY: AlpacaFiberColor = 'DARK_ROSE_GREY';
static readonly   DARK_SILVER_GREY: AlpacaFiberColor = 'DARK_SILVER_GREY';
static readonly   LIGHT_BROWN: AlpacaFiberColor = 'LIGHT_BROWN';
static readonly   LIGHT_FAWN: AlpacaFiberColor = 'LIGHT_FAWN';
static readonly   LIGHT_ROSE_GREY: AlpacaFiberColor = 'LIGHT_ROSE_GREY';
static readonly   LIGHT_SILVER_GREY: AlpacaFiberColor = 'LIGHT_SILVER_GREY';
static readonly   MEDIUM_BROWN: AlpacaFiberColor = 'MEDIUM_BROWN';
static readonly   MEDIUM_FAWN: AlpacaFiberColor = 'MEDIUM_FAWN';
static readonly   MEDIUM_ROSE_GREY: AlpacaFiberColor = 'MEDIUM_ROSE_GREY';
static readonly   MEDIUM_SILVER_GREY: AlpacaFiberColor = 'MEDIUM_SILVER_GREY';
static readonly   MULTICOLOR: AlpacaFiberColor = 'MULTICOLOR';
static readonly   TRUE_BLACK: AlpacaFiberColor = 'TRUE_BLACK';
static readonly   UNKNOWN: AlpacaFiberColor = 'UNKNOWN';
static readonly   WHITE: AlpacaFiberColor = 'WHITE';
};

export interface AnimalIdentifier {
  position: string;
  type: AnimalIdentifierType;
  value: string;
}

export type AnimalIdentifierType = 'EAR_TAG' | 'CHIP'
export class AnimalIdentifierTypeValues {
static readonly   CHIP: AnimalIdentifierType = 'CHIP';
static readonly   EAR_TAG: AnimalIdentifierType = 'EAR_TAG';
};

export type AnimalRecordStatus = 'INACTIVE' | 'PEDIGREE' | 'HERD' | 'APPROVED'
export class AnimalRecordStatusValues {
static readonly   APPROVED: AnimalRecordStatus = 'APPROVED';
static readonly   HERD: AnimalRecordStatus = 'HERD';
static readonly   INACTIVE: AnimalRecordStatus = 'INACTIVE';
static readonly   PEDIGREE: AnimalRecordStatus = 'PEDIGREE';
};

export interface PanonIdentifier {
  animalIdentifiers: AnimalIdentifier[];
  id: string;
}

export type Sex = 'UNKNOWN' | 'MALE' | 'FEMALE'
export class SexValues {
static readonly   FEMALE: Sex = 'FEMALE';
static readonly   MALE: Sex = 'MALE';
static readonly   UNKNOWN: Sex = 'UNKNOWN';
};

export interface Tag {
  herdId: string;
  id: string;
  name: string;
}

