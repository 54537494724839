import axios from "axios";
import AuthService from "./auth-service";
import DateConverter from "./date-converter"

const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
};

const _axios = axios.create();

const configure = () => {
    _axios.defaults.headers.post['Content-Type'] = 'application/json';
    _axios.defaults.headers.patch['Content-Type'] = 'application/json';
    _axios.defaults.headers.put['Content-Type'] = 'application/json';
    _axios.interceptors.request.use((config):any => {
        if (AuthService.isLoggedIn()) {
            const callback = () => {
                if (!config.headers) {
                    config.headers = {};
                }

                config.withCredentials = true;
                config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
                return Promise.resolve(config);
            };
            return AuthService.updateToken(callback);
        }
        return config;
    });
    _axios.interceptors.response.use(originalResponse => {
        originalResponse.data = DateConverter.convertObjectPropertiesFromStringToDate(originalResponse.data);
        return originalResponse;
    });
};

const getAxiosClient = () => _axios;

export const HttpService = {
    HttpMethods,
    configure,
    getAxiosClient,
};

export default HttpService;