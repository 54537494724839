import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Box, SxProps} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Login from "./Login";
import * as PropTypes from "prop-types";
import React, {FunctionComponent} from "react";
import MoreIcon from '@mui/icons-material/MoreVert';
import {RolesHint} from "./roles-hint";

interface ApplicationBarProperties {
    onDrawerClick: () => void
    onOverflowMenuClick: (anchor: HTMLElement) => void;
    showOverflowMenu?: boolean
    drawerWidth: number
}

const ApplicationBar: FunctionComponent<ApplicationBarProperties> = ({onDrawerClick, onOverflowMenuClick, showOverflowMenu = false, drawerWidth}) => {
    const styles = {
        appBar: {
            width: {
                sm: `calc(100% - ${drawerWidth}px)`
            },
            ml: {
                sm: `${drawerWidth}px`
            },
        } as SxProps,
        menuButton: {
            display: {
                sm: "none"
            }
        } as SxProps,
    }

    return (
        <AppBar position="fixed" sx={styles.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    onClick={onDrawerClick}
                    sx={styles.menuButton}
                    edge="start"
                    aria-label="open-drawer"
                    size="large">
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h6" noWrap>Alpacuna Herds</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <RolesHint />
                <Login/>
                {showOverflowMenu && <IconButton
                    size="large"
                    aria-label="display more actions"
                    edge="end"
                    color="inherit"
                    onClick={(event) => onOverflowMenuClick(event.currentTarget)}
                >
                    <MoreIcon/>
                </IconButton>}
            </Toolbar>
        </AppBar>
    );
}

ApplicationBar.propTypes = {
    onDrawerClick: PropTypes.func.isRequired
};

export default ApplicationBar;
