// @flow
import React, {FunctionComponent, useState} from 'react';
import FlexCard from "./flex-card";
import {TextField} from "@mui/material";

interface CommentCardProps {
    onCommentChange: (comment: string) => void
};
const CommentCard: FunctionComponent<CommentCardProps> = ({onCommentChange}) => {
    const [value, setValue] = useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        onCommentChange(event.target.value);
    };

    return (
        <FlexCard title="Kommentar">
            <TextField
                fullWidth
                id="standard-multiline-flexible"
                placeholder="Kommentar hier eingeben"
                multiline
                minRows={4}
                value={value}
                onChange={handleChange}
                variant="standard"
            />
        </FlexCard>
    );
};

export default CommentCard
