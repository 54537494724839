// @flow
import React, {FunctionComponent} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import FlexStack from "../components/Common/flex-stack";
import TreatmentType from "../api/dtos/treatment-type";
import MedicationForm from "../components/AnimalDashboard/TreatmentDialog/medication-form";
import SimpleNumberValueForm from "../components/AnimalDashboard/TreatmentDialog/simple-number-value-form";
import qs from "qs";
import {TreatmentListJson} from "../api/generated/medical-rest";
import {DateTime} from "luxon";
import TreatmentService from "../services/treatment-service";
import RouteService from "../services/route-service";
import SimpleNoteForm from "../components/AnimalDashboard/TreatmentDialog/simple-note-form";

interface TreatmentEditorPathParams {
    animalId: string
};

export interface TreatmentEditorLocationState {
    treatment: TreatmentListJson
}

const TreatmentEditor: FunctionComponent = () => {
    const history = useHistory();
    const pathParams = useParams<TreatmentEditorPathParams>();
    const location = useLocation<TreatmentEditorLocationState>();

    const {animalId} = pathParams;
    const parsedQueryString = qs.parse(location.search, {ignoreQueryPrefix: true});
    const treatmentType = parsedQueryString.treatmentType;
    const panonId = parsedQueryString.panonId ? parsedQueryString.panonId as string : "";


    const treatmentTemplate: TreatmentListJson = location.state && location.state.treatment ?
        location.state.treatment :
        {animalId, dose: 0, drug: "", id: "", note: "", timestamp: DateTime.now(), type: "", unit: "", value: 0}

    const renderFormForType = () => {
        switch (treatmentType) {
            case TreatmentType.vitaminsAndMinerals:
            case TreatmentType.otherMedication:
            case TreatmentType.vaccination:
            case TreatmentType.antiparasitics:
                return <MedicationForm prefill={{...treatmentTemplate, type: treatmentType, unit: "ml"}} onFormSave={handleTreatmentSave} onFormCancel={handleTreatmentFormCancel}/>;
            case TreatmentType.bodyscore:
                return <SimpleNumberValueForm prefill={{...treatmentTemplate, type: treatmentType}} valueFormHelperText="Bodyscore" onFormSave={handleTreatmentSave}
                                              onFormCancel={handleTreatmentFormCancel}/>
            case TreatmentType.weighing:
                return <SimpleNumberValueForm prefill={{...treatmentTemplate, type: treatmentType, unit: "kg"}} valueFormHelperText="Gewicht" onFormSave={handleTreatmentSave}
                                              onFormCancel={handleTreatmentFormCancel}/>
            case TreatmentType.ultrasound:
            case TreatmentType.spittest:
                return <SimpleNoteForm prefill={{...treatmentTemplate, type: treatmentType}} onFormSave={handleTreatmentSave} onFormCancel={handleTreatmentFormCancel}/>
            case TreatmentType.other:
                return <SimpleNoteForm prefill={{...treatmentTemplate, type: treatmentType}} onFormSave={handleTreatmentSave} onFormCancel={handleTreatmentFormCancel}/>
            default:
                return <MedicationForm prefill={{...treatmentTemplate, type: TreatmentType.otherMedication}} onFormSave={handleTreatmentSave}
                                       onFormCancel={handleTreatmentFormCancel}/>;
        }
    }

    const handleTreatmentSave = async (treatment: TreatmentListJson) => {
        treatment.id ? await TreatmentService.updateTreatment(treatment) : await TreatmentService.addTreatment(treatment);
        history.push(RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId}))
    }

    const handleTreatmentFormCancel = async () => {
        history.push(RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId}))
    }

    return (
        <FlexStack>
            {renderFormForType()}
        </FlexStack>
    );
};

export default TreatmentEditor
