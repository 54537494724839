import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import AuthService from "./services/auth-service";
import HttpService from "./services/http-service";
import "./services/i18n";

const renderApp = () => ReactDOM.render(
    // https://github.com/remix-run/history/issues/435#issuecomment-287853906:
    <Router basename={`${window.location.pathname}#`}>
        <App/>
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

HttpService.configure();
AuthService.initKeycloak(renderApp);
